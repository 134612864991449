import React, { useState } from 'react';
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import { db, auth } from "../../firebase";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Mail, Calendar, HeartPulse, X, Trash2 } from 'lucide-react';
import DeleteAccountModal from './DeleteAccountModal';

const EditPersonalInfo = ({ show, onClose, userInfo, userId }) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    username: userInfo?.username || '',
    email: userInfo?.email || '',
    dateOfBirth: userInfo?.dateOfBirth ? new Date(userInfo.dateOfBirth.seconds * 1000).toISOString().split('T')[0] : '',
    medicalCondition: userInfo?.medicalCondition || ''
  });

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        ...formData,
        dateOfBirth: new Date(formData.dateOfBirth)
      });
      toast.success(t('dadosAtualizadosPopup'));
      onClose();
    } catch (error) {
      toast.error(t('dadosAtualizadosPopupError'));
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      await deleteDoc(doc(db, "users", userId));
      await deleteUser(user);
      toast.success(t('accountDeleted'));
      window.location.href = '/';
    } catch (error) {
      toast.error(t('errorDeletingAccount'));
      console.error('Error deleting account:', error);
    }
  };

  if (!show) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={onClose}
          />

          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="relative w-full max-w-md bg-white rounded-2xl shadow-xl"
          >
            <div className="relative bg-gradient-to-br from-red-500 via-red-600 to-red-500 p-6 rounded-t-2xl">
              <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.2),rgba(255,255,255,0))] rounded-t-2xl"></div>
              <button
                onClick={onClose}
                className="absolute right-4 top-4 w-8 h-8 flex items-center justify-center text-white/80 hover:text-white rounded-full hover:bg-white/10 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
              <div className="relative">
                <h3 className="text-xl font-semibold text-white">
                  {t('editarInformacoes')}
                </h3>
                <p className="mt-1 text-sm text-white/90">
                  {t('atualizarInformacoes')}
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              <div className="space-y-4">
                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    {t('Username')}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="Username"
                      value={formData.username}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500/20 focus:border-red-500 transition-colors"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    {t('Email')}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      name="Email"
                      value={formData.email}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500/20 focus:border-red-500 transition-colors"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    {t('dateOfBirth')}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Calendar className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="date"
                      name="dateOfBirth"
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500/20 focus:border-red-500 transition-colors"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    {t('condicaoMedica')}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <HeartPulse className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name={t('condicaoMedica')}
                      value={formData.medicalCondition}
                      onChange={handleChange}
                      className="block w-full pl-10 pr-3 py-2.5 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500/20 focus:border-red-500 transition-colors"
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-3 pt-4">
                <div className="flex flex-col-reverse sm:flex-row gap-3">
                  <button
                    type="button"
                    onClick={onClose}
                    className="w-full sm:w-1/2 px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                  >
                    {t('cancelar')}
                  </button>
                  <button
                    type="submit"
                    className="w-full sm:w-1/2 px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 rounded-lg transition-colors shadow-sm"
                  >
                    {t('save')}
                  </button>
                </div>
                
                <div className="pt-4 border-t">
                  <button
                    type="button"
                    onClick={() => setShowDeleteModal(true)}
                    className="w-full flex items-center justify-center gap-2 px-4 py-2.5 text-sm font-medium text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <Trash2 size={16} />
                    {t('deleteAccount')}
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </div>

      <DeleteAccountModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteAccount}
        t={t}
      />
    </AnimatePresence>
  );
};

export default EditPersonalInfo;