import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronRight, MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const FAQSection = () => {
    const { t } = useTranslation();

    const faqs = [
        {
            question: t('Question1'),
            answer: t('Answer1')
        },
        {
            question: t('Question2'),
            answer: t('Answer2')
        },
        {
            question: t('Question3'),
            answer: t('Answer3')
        },
        {
            question: t('Question4'),
            answer: t('Answer4')
        },
        {
            question: t('Question5'),
            answer: t('Answer5')
        },
        {
          question: t('Question6'),
          answer: t('Answer6')
        },
        {
          question: t('Question7'),
          answer: t('Answer7')
        }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="bg-gradient-to-b from-gray-900 via-gray-900 to-black relative overflow-hidden py-32 px-6 md:px-12 lg:px-24">
            {/* Background Effects */}
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:14px_24px]" />
                <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse" />
                <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse delay-1000" />
            </div>

            {/* Content */}
            <div className="relative z-10 max-w-4xl mx-auto">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-16"
                >
                    <span className="text-red-500 font-semibold text-sm tracking-wider uppercase mb-4 inline-flex items-center gap-2">
                        <MessageCircle className="w-4 h-4" />
                        {t('support')}
                    </span>
                    <h2 className="text-4xl sm:text-5xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                        {t('faqTitle')}
                    </h2>
                    <p className="text-lg text-gray-400">
                        {t('faqSubtitle')}
                    </p>
                </motion.div>

                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                            className="group"
                        >
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full bg-white/5 backdrop-blur-sm border border-gray-800 hover:border-red-500/30 rounded-xl p-6 text-left transition-all duration-300"
                            >
                                <div className="flex justify-between items-center w-full">
                                    <span className="text-white font-medium">{faq.question}</span>
                                    <ChevronDown
                                        className={`w-5 h-5 text-gray-400 transition-transform duration-300 ${
                                            openIndex === index ? 'rotate-180' : ''
                                        }`}
                                    />
                                </div>
                                <div
                                    className={`mt-4 text-gray-400 overflow-hidden transition-all duration-300 ${
                                        openIndex === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                                    }`}
                                >
                                    <div className="border-t border-gray-800 pt-4">
                                        {faq.answer}
                                    </div>
                                </div>
                            </button>
                        </motion.div>
                    ))}
                </div>

                {/* Still have questions CTA */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="mt-16 text-center"
                >
                    <p className="text-gray-400 mb-6">
                        {t('stillQuestions')}
                    </p>
                    <a
                        href="https://calendly.com/tiago-costa-bloodflow"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center justify-center px-6 py-3 rounded-xl bg-white text-red-600 font-medium hover:bg-gray-50 transition-all duration-300"
                    >
                        <span className="flex items-center">
                            {t('contactUs')}
                            <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                        </span>
                    </a>
                </motion.div>
            </div>
        </div>
    );
};

export default FAQSection;