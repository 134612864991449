import React, {useState} from 'react';
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import {auth} from "../../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronRight } from "lucide-react";
import { motion } from 'framer-motion';

function ForgotPassword() {

    const navigate = useNavigate();
    const [email, setEmail] = useState(''); 
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            sendPasswordResetEmail(auth, email);
            toast.success(t('emailSent'));
            navigate("/login");
        } catch (error) {
            setLoading(false);
            toast.error(t('emailError'));
        }
    };

    const backToHome = () => {
        navigate("/");
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-red-50 via-white to-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-md w-full space-y-8 bg-white/70 backdrop-blur-xl rounded-2xl shadow-xl p-8 border border-gray-100"
          >
            <div>
              <motion.img
                whileHover={{ scale: 1.05 }}
                onClick={backToHome}
                className="mx-auto h-16 w-auto cursor-pointer"
                src={BloodFlowLogo}
                alt="BloodFlow Logo"
              />
              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="mt-6 text-center text-3xl font-extrabold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
              >
                {t('forgotMyPassword')}
              </motion.h1>
            </div>
      
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              onSubmit={sendEmail}
              className="mt-8 space-y-6"
            >
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <div className="relative">
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200"
                  />
                </div>
              </div>
      
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white shadow-lg transition-all duration-300 bg-gradient-to-r from-red-600 to-red-500 hover:from-red-500 hover:to-red-600"
              >
                <span className="absolute right-4 transition-transform duration-200 group-hover:translate-x-1">
                  <ChevronRight className="h-5 w-5" />
                </span>
                {loading ? t('sendingEmail') : t('sendEmail')}
              </motion.button>
      
              {/* Optional: Back to Login Link */}
              <div className="text-center">
                <RouterLink 
                  to="/login"
                  className="font-medium text-sm text-gray-600 hover:text-gray-500 transition-colors duration-200"
                >
                  ← {t('backToLogin')}
                </RouterLink>
              </div>
            </motion.form>
          </motion.div>
        </div>
      );
}

export default ForgotPassword;