import React from "react";
//import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import wslisbon from "../../Assets/wslisbon.jpg";
import SLUX from "../../Assets/SULx_01.png";
import UFLX from "../../Assets/uflx.jpg";
import wsimpact from "../../Assets/wsImpactNew.png";
import { ChevronRight, Check, Clock, AlertCircle, BrainCircuit, Zap, Shield, LineChart, Gift } from 'lucide-react';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GDPR from './GDPR';
import { motion } from 'framer-motion';
import WorkflowVisualization from './WorkflowVisualization';

const Services = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isAnnual, setIsAnnual] = useState(true);
    const [loading, setLoading] = useState(false);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [showTrialDrawer, setShowTrialDrawer] = useState(false);

    useEffect(() => {
        if (window.location.hash === '#pricing') {
            const element = document.getElementById('pricing');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    const prices = {
      monthly: {
          amount: 15,
          currency: 'usd',
          stripeId: 'price_monthly_id'
      },
      annual: {
          amount: 100,
          currency: 'usd',
          stripeId: 'price_annual_id'
      }
    };
    
    const currentPriceConfig = isAnnual ? prices.annual : prices.monthly;
    
    const saving = Math.round(((prices.monthly.amount * 12 - prices.annual.amount) / (prices.monthly.amount * 12)) * 100);

    const handleSubscribe = async (isAnnual) => {
      setLoading(true);

      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
          // Create URL search params with plan data
          const planData = {
              planType: isAnnual ? 'annual' : 'monthly',
              priceId: isAnnual ? 
                  process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID : 
                  process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
              price: isAnnual ? prices.annual.amount : prices.monthly.amount
          };
  
          // Navigate to signup with plan data in URL
          navigate('/signup', { 
            state: { planData } 
          });
          return;
      }
  
      try {
          const idToken = await user.getIdToken();
          const subscriptionStatus = await fetch(`${BASE_URL}/subscription/status/${user.uid}`, {
              headers: {
                  'Authorization': `Bearer ${idToken}`
              }
          });
          const { status } = await subscriptionStatus.json();

          if (status === 'pro') {
              setLoading(false);
              toast.info('You already have an active subscription!');
              return;
          }

          const stripe = await stripePromise;
              
          const response = await fetch(`${BASE_URL}/subscription/create-checkout`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${idToken}`
              },
              body: JSON.stringify({
                  priceId: isAnnual ? 
                      process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID : 
                      process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
                      email: user.email, 
                      userId: user.uid
                  })
              });
  
          const { sessionId } = await response.json();
          
          const result = await stripe.redirectToCheckout({ sessionId });
  
          if (result.error) {
              setLoading(false);
          }
      } catch (error) {
          setLoading(false);
      } finally {
          setLoading(false);
      }
  };

    const handleFreeTrial = () => {
        navigate('/signup', { 
            state: { planData: { planType: 'trial', trialAnalyses: 5 } } 
        });
    };

    const problems = [
      {
        icon: <Clock className="w-6 h-6" />,
        title: t('problem1Title'),
        description: t('problem1Desc'),
        stat: '+2h',
        statLabel: t('timeWasted')
      },
      {
        icon: <AlertCircle className="w-6 h-6" />,
        title: t('problem2Title'),
        description: t('problem2Desc'),
        stat: '35%',
        statLabel: t('errorRate')
      },
      {
        icon: <BrainCircuit className="w-6 h-6" />,
        title: t('problem3Title'),
        description: t('problem3Desc'),
        stat: '45%',
        statLabel: t('cognitiveLoad')
      }
    ];

    const solutions = [
      {
        icon: <Zap className="w-6 h-6" />,
        title: t('solution1Title'),
        description: t('solution1Desc'),
      },
      {
        icon: <Shield className="w-6 h-6" />,
        title: t('solution2Title'),
        description: t('solution2Desc'),
      },
      {
        icon: <LineChart className="w-6 h-6" />,
        title: t('solution3Title'),
        description: t('solution3Desc'),
      }
    ];

    const metrics = [
      {
        value: 98.3,
        suffix: '%',
        label: t('accuracyImprovement'),
        description: t('accuracyImprovementDesc'),
        trend: '+23.5%',
        isPositive: true
      },
      {
        value: 95,
        suffix: '%',
        label: t('timeReduction'),
        description: t('timeReductionDesc'),
        trend: '-95%',
        isPositive: true
      },
      {
        value: 159000,
        prefix: '€',
        label: t('costSavings'),
        description: t('costSavingsDesc'),
        trend: '-65%',
        isPositive: true
      },
      {
        value: 45,
        suffix: '%',
        label: t('errorReduction'),
        description: t('errorReductionDesc'),
        trend: '-45%',
        isPositive: true
      }
    ];

    return (
        <div className="w-full bg-white overflow-hidden">
            {/* Partners Section - Modernized */}
            <motion.div 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="max-w-7xl mx-auto py-24 px-6"
            >
                <div className="text-center mb-16">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="text-xl font-bold text-gray-900"
                    >
                        {t('backed')}
                    </motion.h2>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
                  {[SLUX, UFLX, wslisbon, wsimpact].map((logo, index) => (
                      <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          transition={{ delay: index * 0.1 }}
                          className="w-28 h-28 flex items-center justify-center" // Fixed container size
                      >
                          <img
                              src={logo}
                              alt="Partner Logo"
                              className="w-full h-full object-contain filter grayscale hover:grayscale-0 transition-all duration-300 px-4"
                              style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%'
                              }}
                          />
                      </motion.div>
                  ))}
              </div>
            </motion.div>

            {/* Problems Section - New Design */}
            <div className="bg-white relative">
              <div id="features" className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]" />
                <div className="relative max-w-7xl mx-auto py-24 px-6">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-16"
                  >
                  <span className="text-red-600 font-semibold text-sm tracking-wider uppercase mb-4 block">
                    {t('currentChallenges')}
                  </span>
                  <h2 className="text-3xl font-bold text-gray-900 mb-4">
                    {t('whyTraditionalFalls')}
                  </h2>
                  <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                    {t('traditionalDesc')}
                  </p>
                </motion.div>

                <div className="grid md:grid-cols-3 gap-8">
                  {problems.map((problem, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="relative group"
                    >
                      <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                        <div className="h-12 w-12 bg-red-50 rounded-lg flex items-center justify-center text-red-600 mb-6">
                          {problem.icon}
                        </div>
                        <h3 className="text-xl font-semibold text-gray-900 mb-4">
                          {problem.title}
                        </h3>
                        <p className="text-gray-600 mb-6">
                          {problem.description}
                        </p>
                        <div className="pt-6 border-t border-gray-100">
                          <div className="flex items-baseline">
                            <span className="text-3xl font-bold text-red-600">
                              {problem.stat}
                            </span>
                            <span className="ml-2 text-sm text-gray-500">
                              {problem.statLabel}
                            </span>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            {/* Workflow Visualization */}
            <WorkflowVisualization />

            {/* Solution Steps - Redesigned */}
            <div className="bg-white relative">
              <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]" />
              <div className="relative max-w-7xl mx-auto py-24 px-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  className="text-center mb-16"
                >
                  <span className="text-red-600 font-semibold text-sm tracking-wider uppercase mb-4 block">
                    {t('ourApproach')}
                  </span>
                  <h2 className="text-3xl font-bold text-gray-900 mb-4">
                    {t('howBloodFlowWorks')}
                  </h2>
                  <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                    {t('solutionDesc')}
                  </p>
                </motion.div>

                <div className="grid md:grid-cols-3 gap-8">
                  {solutions.map((solution, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.2 }}
                      className="relative group h-full"
                    >
                      <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 h-full flex flex-col">
                        <div className="h-12 w-12 bg-red-50 rounded-lg flex items-center justify-center text-red-600 mb-6 flex-shrink-0">
                          {solution.icon}
                        </div>
                        <h3 className="text-xl font-semibold text-gray-900 mb-4">
                          {solution.title}
                        </h3>
                        <p className="text-gray-600 flex-grow">
                          {solution.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            {/* Metrics Dashboard Section - New */}
            <div className="bg-gray-900 relative overflow-hidden">
              <div className="absolute inset-0">
                <div className="absolute inset-0 bg-gradient-to-br from-red-500/10 to-blue-500/10 mix-blend-overlay" />
                <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse" />
                <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse delay-1000" />
              </div>
              
              <div className="relative max-w-7xl mx-auto py-24 px-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  className="text-center mb-16"
                >
                  <span className="text-red-400 font-semibold text-sm tracking-wider uppercase mb-4 block">
                    {t('realResults')}
                  </span>
                  <h2 className="text-4xl font-bold text-white mb-4">
                    {t('impactMetrics')}
                  </h2>
                  <p className="text-xl text-gray-400 max-w-3xl mx-auto">
                    {t('metricsDesc')}
                  </p>
                </motion.div>

                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                  {metrics.map((metric, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 border border-white/10"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-medium text-gray-300">
                          {metric.label}
                        </h3>
                        <span className={`text-sm font-semibold px-2 py-1 rounded-full ${
                          metric.isPositive ? 'bg-green-500/20 text-green-400' : 'bg-red-500/20 text-red-400'
                        }`}>
                          {metric.trend}
                        </span>
                      </div>
                      <div className="flex items-baseline gap-2">
                        <span className="text-4xl font-bold text-white">
                          {metric.prefix}{metric.value}{metric.suffix}
                        </span>
                      </div>
                      <p className="mt-2 text-sm text-gray-400">
                        {metric.description}
                      </p>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            <GDPR />

            <div className="relative">
                {/* New Pricing Section */}
                <div id="pricing" className="bg-gradient-to-b from-gray-900 via-black to-gray-900 py-24 px-6 md:px-12 lg:px-24 relative overflow-hidden">
                    {/* Background Effects */}
                    <div className="absolute inset-0">
                      <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:14px_24px]" />
                      <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse" />
                      <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse delay-1000" />
                    </div>

                    <div className="relative max-w-7xl mx-auto">
                      {/* Header */}
                      <div className="text-center mb-20">
                        <motion.span
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          viewport={{ once: true }}
                          className="text-red-500 font-semibold text-sm tracking-wider uppercase mb-4 block"
                        >
                          {t('pricing')}
                        </motion.span>
                        <motion.h2
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          className="text-4xl sm:text-5xl font-bold mb-6 text-white"
                        >
                          {t('choosePlan')}
                        </motion.h2>
                        <motion.p
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          className="text-xl text-gray-400 max-w-2xl mx-auto"
                        >
                          {t('pricingDesc')}
                        </motion.p>
                      </div>

                      <div className="grid lg:grid-cols-2 gap-8 lg:gap-12">
                        {/* Individual Plan */}
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          className="relative"
                        >
                          {/* Trial Drawer */}
                          <motion.div
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ 
                                  opacity: showTrialDrawer ? 1 : 0,
                                  y: showTrialDrawer ? 0 : 20
                              }}
                              className={`fixed inset-0 bg-gray-900/80 z-50 flex items-center justify-center ${
                                  showTrialDrawer ? 'pointer-events-auto' : 'pointer-events-none'
                              }`}
                          >
                              <motion.div 
                                  className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 lg:p-8 border border-red-500/30 w-full max-w-md m-4"
                                  initial={{ scale: 0.95 }}
                                  animate={{ scale: showTrialDrawer ? 1 : 0.95 }}
                              >
                                  <div className="h-full flex flex-col">
                                      <button 
                                          onClick={() => setShowTrialDrawer(false)}
                                          className="absolute top-4 right-4 text-gray-400 hover:text-white"
                                      >
                                          ×
                                      </button>
                                      
                                      <div className="flex items-center gap-3 mb-4">
                                          <div className="p-2 bg-red-500/20 rounded-lg">
                                              <Gift className="w-6 h-6 text-red-400" />
                                          </div>
                                          <h3 className="text-xl font-bold text-white">{t('tryForFree')}</h3>
                                      </div>

                                      <p className="text-gray-400 mb-6">
                                          {t('trialDetailedDesc')}
                                      </p>

                                      <div className="bg-white/10 rounded-lg p-4 mb-6">
                                          <div className="flex items-center justify-between mb-2">
                                              <span className="text-gray-300">{t('freeAnalyses')}</span>
                                              <span className="text-white font-bold">5</span>
                                          </div>
                                          <div className="flex items-center justify-between">
                                              <span className="text-gray-300">{t('validity')}</span>
                                              <span className="text-white font-bold">{t('days')}</span>
                                          </div>
                                      </div>

                                      <button 
                                          onClick={handleFreeTrial}
                                          className="mt-auto w-full group relative inline-flex items-center justify-center px-6 py-3 rounded-xl bg-red-500 text-white font-medium hover:bg-red-400 transition-all duration-300"
                                      >
                                          <span className="relative z-10 flex items-center">
                                              {t('startFreeTrial')}
                                              <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                                          </span>
                                      </button>
                                  </div>
                              </motion.div>
                          </motion.div>

                          {/* Main Card Content */}
                          <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 lg:p-8 h-full border border-gray-700/50 flex flex-col relative">
                              {/* Trial Badge - Move above other content */}
                              <div className="absolute -top-3 left-4 z-20">
                                  <button
                                      onClick={() => setShowTrialDrawer(true)}
                                      className="px-3 py-1 bg-gradient-to-r from-red-500 to-red-600 text-white text-xs font-semibold rounded-full hover:from-red-600 hover:to-red-700 transition-all duration-300 flex items-center gap-1 shadow-lg"
                                  >
                                      <Gift className="w-3 h-3" />
                                      {t('freeTrial')}
                                  </button>
                              </div>

                              <div className="mb-6">
                                  <h3 className="text-xl font-bold text-white mb-2">{t('individualPlan')}</h3>
                                  <p className="text-sm text-gray-400">{t('individualPlanDesc')}</p>
                              </div>

                              {/* Rest of the original card content... */}
                              <div className="flex items-center gap-4 mb-6">
                                <span className={`text-sm ${!isAnnual ? 'text-white' : 'text-gray-400'}`}>
                                  {t('monthly')}
                                </span>
                                <button 
                                  onClick={() => setIsAnnual(!isAnnual)}
                                  className="relative w-12 h-6 rounded-full bg-gray-700 transition-colors duration-300"
                                >
                                  <div className={`absolute left-1 top-1 w-4 h-4 rounded-full bg-red-500 transition-transform duration-300 ${isAnnual ? 'translate-x-6' : 'translate-x-0'}`} />
                                </button>
                                <span className={`text-sm ${isAnnual ? 'text-white' : 'text-gray-400'}`}>
                                  {t('annual')}
                                  <span className="ml-1 text-red-500">
                                    (-{saving}%)
                                  </span>
                                </span>
                              </div>

                              <div className="mb-6">
                                <div className="flex items-baseline gap-2">
                                  <span className="text-4xl font-bold text-white">${currentPriceConfig.amount}</span>
                                  <span className="text-gray-400 text-sm">/{isAnnual ? 'year' : 'month'}</span>
                                </div>
                              </div>

                              {/* Features List */}
                              <div className="flex-grow">
                                <ul className="space-y-3 mb-8">
                                  <li className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-green-500 mr-3" />
                                    {t('unlimitedAnalysis')}
                                  </li>
                                  <li className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-green-500 mr-3" />
                                    {t('advHealth')}
                                  </li>
                                  <li className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-green-500 mr-3" />
                                    {t('longitudinal')}
                                  </li>
                                  <li className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-green-500 mr-3" />
                                    {t('BioMarkers')}
                                  </li>
                                  <li className="flex items-center text-gray-300">
                                    <Check className="w-5 h-5 text-green-500 mr-3" />
                                    {t('priority')}
                                  </li>
                                </ul>
                              </div>

                              <button 
                                onClick={() => handleSubscribe(isAnnual)}
                                disabled={loading}
                                className="w-full group relative inline-flex items-center justify-center px-6 py-3 rounded-xl bg-red-600 text-white font-medium hover:bg-red-500 transition-all duration-300"
                              >
                                <span className="relative z-10 flex items-center">
                                  {loading ? (
                                    <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                                  ) : (
                                    <>
                                      {t('getProAccess')}
                                      <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                                    </>
                                  )}
                                </span>
                              </button>
                          </div>
                        </motion.div>

                        {/* Enterprise Plan */}
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          className="relative"
                        >
                          <div className="bg-gradient-to-br from-red-600/20 to-gray-900 rounded-2xl p-6 lg:p-8 h-full border border-red-500/30 flex flex-col">
                            <div className="absolute -top-3 left-4 px-3 py-1 bg-red-500 text-white text-xs font-semibold rounded-full">
                              {t('enterprise')}
                            </div>

                            <div className="mb-6">
                              <h3 className="text-xl font-bold text-white mb-2">{t('enterprisePlan')}</h3>
                              <p className="text-sm text-gray-400">{t('enterprisePlanDesc')}</p>
                            </div>

                            <div className="mb-6">
                              <div className="flex items-baseline gap-2">
                                <span className="text-2xl font-bold text-white">{t('customPricing')}</span>
                              </div>
                              <p className="mt-1 text-sm text-gray-400">{t('customPricingDesc')}</p>
                            </div>

                            {/* Enterprise Features */}
                            <div className="flex-grow">
                              <ul className="space-y-3 mb-8">
                                <li className="flex items-center text-gray-300">
                                  <Check className="w-5 h-5 text-red-500 mr-3" />
                                  {t('unlimitedTests')}
                                </li>
                                <li className="flex items-center text-gray-300">
                                  <Check className="w-5 h-5 text-red-500 mr-3" />
                                  {t('ehrIntegration')}
                                </li>
                                <li className="flex items-center text-gray-300">
                                  <Check className="w-5 h-5 text-red-500 mr-3" />
                                  {t('prioritySupport')}
                                </li>
                                <li className="flex items-center text-gray-300">
                                  <Check className="w-5 h-5 text-red-500 mr-3" />
                                  {t('dedicatedManager')}
                                </li>
                                <li className="flex items-center text-gray-300">
                                  <Check className="w-5 h-5 text-red-500 mr-3" />
                                  {t('customAnalytics')}
                                </li>
                              </ul>
                            </div>

                            <a 
                              href="https://calendly.com/tiago-costa-bloodflow"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="w-full group relative inline-flex items-center justify-center px-6 py-3 rounded-xl bg-white text-red-600 font-medium hover:bg-gray-50 transition-all duration-300"
                            >
                              <span className="relative z-10 flex items-center">
                                {t('scheduleCall')}
                                <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                              </span>
                            </a>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
    );
};

export default Services;