import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRight, Sparkles } from "lucide-react";
import { motion } from 'framer-motion';

const AnimatedNumber = ({ value, decimals = 0, suffix = '', prefix = '' }) => {
  const [displayValue, setDisplayValue] = useState(0);
  
  useEffect(() => {
    const duration = 3000;
    const steps = 60;
    const stepDuration = duration / steps;
    let currentStep = 0;

    const timer = setInterval(() => {
      currentStep++;
      const progress = currentStep / steps;
      const currentValue = Math.min(progress * value, value);
      setDisplayValue(Number(currentValue.toFixed(decimals)));

      if (currentStep === steps) {
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [value, decimals]);

  return (
    <span className="text-4xl font-bold bg-gradient-to-r from-red-600 to-red-500 bg-clip-text text-transparent">
      {prefix}{displayValue}{suffix}
    </span>
  );
};

const Hero = () => {
  const { t } = useTranslation();

  const stats = [
    { 
      value: 10000, 
      label: t('patientsAnalyzed'), 
      suffix: '+',
      description: t('patientsAnalyzedDesc')
    },
    { 
      value: 95, 
      label: t('clinicianTime'), 
      suffix: '%',
      description: t('clinicianTimeDesc')
    },
    { 
      value: 98.3, 
      label: t('diagnosticAccuracy'), 
      suffix: '%',
      description: t('diagnosticAccuracyDesc')
    },
    { 
      value: 159, 
      prefix: '€', 
      label: t('revenueIncrease'), 
      suffix: 'K',
      description: t('revenueIncreaseDesc')
    }
  ];

  return(
    <div id="" className="relative min-h-screen bg-gradient-to-b from-white via-gray-50 to-white flex items-center overflow-hidden">
      {/* Background patterns */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]" />
        <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-red-50 blur-[100px]" />
        <div className="absolute right-0 top-0 -z-10 h-[310px] w-[310px] rounded-full bg-blue-50 blur-[100px]" />
      </div>

      <div className="relative container mx-auto px-4 pt-20 pb-24 flex flex-col lg:flex-row items-center gap-16">
        {/* Left side - Content */}
        <motion.div 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full lg:w-1/2"
        >
          {/* New Feature Badge */}
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="mb-8"
          >
            <span className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-red-50 border border-red-100">
              <span className="relative flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500" />
              </span>
              <span className="text-sm font-medium text-red-600">{t('newFeature')}</span>
            </span>
          </motion.div>

          {/* Enhanced Title and Subtitle */}
          <motion.div 
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="space-y-6"
          >
            <div className="space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-center gap-2 text-red-600 font-semibold"
              >
                <Sparkles className="h-5 w-5" />
                <span className="text-sm uppercase tracking-wider">Next-Gen Healthcare Solution</span>
              </motion.div>
              
              <h1 className="text-4xl lg:text-5xl font-bold leading-tight">
                <span className="inline-block">
                  <span className="bg-gradient-to-br from-gray-900 to-gray-700 bg-clip-text text-transparent">
                    AI-Driven Blood Test
                  </span>
                </span>
                <br />
                <span className="relative inline-block">
                  <span className="absolute -inset-1 bg-red-100 rounded-lg -skew-y-3 transform" />
                  <span className="relative inline-block bg-gradient-to-r from-red-600 via-red-500 to-red-600 bg-clip-text text-transparent">
                    Analysis
                  </span>
                </span>
                <br />
                <span className="inline-block mt-2 text-4xl lg:text-5xl">
                  Seamlessly Integrated with Your
                  <span className="text-red-600"> EHR</span>
                </span>
              </h1>
              
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="text-xl text-gray-600 max-w-xl mt-6"
              >
                {t('subtitle3')}
              </motion.p>
            </div>
          </motion.div>

          {/* CTA Buttons with fixed widths */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
            className="flex flex-col sm:flex-row gap-4 mt-12 w-full sm:w-auto"
          >
            <RouterLink to="/signup" className="w-full sm:w-auto">
              <button className="w-full sm:w-auto group relative px-8 py-4 rounded-xl bg-red-600 text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-0.5 whitespace-nowrap">
                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="relative z-10 flex items-center justify-center">
                  {t('buttonHero4')}
                  <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                </span>
              </button>
            </RouterLink>
            <a
              href="https://calendly.com/tiago-costa-bloodflow"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto inline-block"
            >
              <button className="w-full sm:w-auto group px-8 py-4 rounded-xl bg-white border border-gray-200 text-gray-700 font-semibold shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-0.5 whitespace-nowrap">
                {t('buttonHero3')} 
                <ChevronRight className="inline-block ml-2 size-4 transition-transform group-hover:translate-x-1" />
              </button>
            </a>
          </motion.div>
        </motion.div>

        {/* Right side - Stats Grid */}
        <motion.div 
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full lg:w-1/2"
        >
          <div className="grid grid-cols-2 gap-6">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="group relative bg-white p-6 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-red-50 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="relative space-y-2">
                  <div className="flex items-baseline">
                    <AnimatedNumber 
                      value={stat.value} 
                      prefix={stat.prefix}
                      decimals={stat.value % 1 !== 0 ? 1 : 0}
                      suffix={stat.suffix} 
                    />
                  </div>
                  <p className="font-semibold text-gray-800">{stat.label}</p>
                  <p className="text-sm text-gray-600">{stat.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
