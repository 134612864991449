import React from 'react';
import { Shield, Lock, UserCircle, FileText, ExternalLink, CheckCircle2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const GDPRSection = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: <Lock className="w-6 h-6" />,
      title: t('dataProt'),
      description: t('yourData'),
      highlights: ['HIPAA Compliant', 'End-to-end Encryption', 'Secure Cloud Storage']
    },
    {
      icon: <UserCircle className="w-6 h-6" />,
      title: t('userControl'),
      description: t('fullControl'),
      highlights: ['Data Portability', 'Access Controls', 'User Consent Management']
    },
    {
      icon: <FileText className="w-6 h-6" />,
      title: t('transparentProc'),
      description: t('clearDoc'),
      highlights: ['Audit Trails', 'Processing Records', 'Regular Updates']
    }
  ];

  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white py-24">
      <div className="max-w-7xl mx-auto px-6">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <div className="flex justify-center mb-6">
            <div className="bg-red-600/10 p-3 rounded-xl">
              <Shield className="w-8 h-8 text-red-600" />
            </div>
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            {t('gdprCompliant')}
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {t('atBloodFlow')}
          </p>
        </motion.div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="relative group"
            >
              <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 h-full">
                <div className="bg-red-50 p-3 rounded-xl w-fit mb-6 group-hover:bg-red-600 transition-colors duration-300">
                  <div className="text-red-600 group-hover:text-white transition-colors duration-300">
                    {feature.icon}
                  </div>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">
                  {feature.title}
                </h3>
                <p className="text-gray-600 mb-6">
                  {feature.description}
                </p>
                <ul className="space-y-3">
                  {feature.highlights.map((highlight, idx) => (
                    <li key={idx} className="flex items-center text-sm text-gray-500">
                      <CheckCircle2 className="w-4 h-4 text-red-500 mr-2" />
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Bottom CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="flex flex-col items-center"
        >
          <a 
            href="https://drive.google.com/file/d/1YBFUR6iIhu-v2t83AvJLdngwKhRhg7ec/view?usp=drive_link"
            className="group flex items-center gap-2 px-6 py-4 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileText className="w-5 h-5 text-red-600" />
            <span className="font-medium text-gray-900">{t('PrivacyPolicy')}</span>
            <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-red-600 transition-colors duration-300" />
          </a>
          
          <div className="mt-8 flex items-center gap-2 text-sm text-gray-500">
            <Shield className="w-4 h-4 text-red-500" />
            {t('yourDataIsPro')}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default GDPRSection;