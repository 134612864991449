import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const parseAnalysisText = (markdownText) => {
  if (!markdownText) return { score: null, sections: [] };

  try {
        const scorePattern = /(?:\*\*)?(?:Score de Saúde|Health Score|Score)(?:\*\*)?:\s*([\d.]+)/i;

        const datePattern = /(?:\*\*)?(?:Data da Análise|Analysis Date)(?:\*\*)?:\s*(\d{2}\/\d{2}\/\d{4})/i;
        
        const trendPattern = /(?:\*\*)?(?:Indicador de Tendência|Trend Indicator)(?:\*\*)?:\s*(?:\(?)*(→|↑|↓)\s*(estável|melhoria|agravamento|stable|improving|deteriorating)(?:\))?/i;

        // Extract score
        const scoreMatch = markdownText.match(scorePattern);
        const score = scoreMatch ? parseFloat(scoreMatch[1]) : null;

        // Extract date
        const dateMatch = markdownText.match(datePattern);
        const date = dateMatch ? dateMatch[1] : '';

        // Extract and map trend
        const trendMatch = markdownText.match(trendPattern);
        let trend = '→';
        let trendText = 'stable';

        if (trendMatch) {
            trend = trendMatch[1] || '→';
            trendText = trendMatch[2] || 'estável';
        }

        const scoreData = {
        score,
        date,
        trend,
        trendText
        };

        // Enhanced section parsing for both languages
        const sections = [];
        // Match both numbered sections with titles in both languages
        const sectionRegex = /(?:\*\*(\d+)\.\s+([^*]+)\*\*|\b(\d+)\.\s+\*\*([^*]+)\*\*)/g;

        // Track the last position where we found a match
        let lastMatchEnd = 0;
        let match;

      while ((match = sectionRegex.exec(markdownText)) !== null) {
          const sectionNumber = match[1] || match[3];
          const sectionTitle = (match[2] || match[4]).replace(/^[^a-zA-Z]+/, '').trim();
          const matchEnd = match.index + match[0].length;

          if (sections.length > 0) {
              const previousSection = sections[sections.length - 1];
              previousSection.content = markdownText
                  .slice(lastMatchEnd, match.index)
                  .trim()
                  .replace(/^\s*-\s*/, '');
          }

          if (sectionNumber !== '1') {
              sections.push({
                  id: sectionNumber,
                  title: sectionTitle,
                  content: ''
              });
          }

          lastMatchEnd = matchEnd;
      }

      if (sections.length > 0) {
          const lastSection = sections[sections.length - 1];
          lastSection.content = markdownText
              .slice(lastMatchEnd)
              .trim()
              .replace(/^\s*-\s*/, '');
      }

      sections.forEach(section => {
          section.content = section.content
              .replace(/\*\*([A-Z])\.\s+([^*]+)\*\*/g, '### $1. $2')
              .trim();
      });

      return { score: scoreData, sections };
  } catch (error) {
      console.error('Error parsing analysis text:', error);
      return { score: null, sections: [] };
  }
};

// Component to display sections
const AnalysisSection = ({ title, content, isOpen, onToggle, sectionNumber }) => {
    return (
      <div className="mb-6 overflow-hidden group">
        {/* Section Header */}
        <button
          onClick={onToggle}
          className="w-full relative flex items-center gap-6 p-6 bg-white rounded-2xl border border-gray-100 
                     hover:border-red-100 transition-all duration-300 group-hover:shadow-md"
        >
          {/* Section Number */}
          <div className="relative flex-shrink-0">
            <div className="absolute inset-0 bg-red-100 rounded-full blur-lg animate-pulse opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            <div className="relative w-12 h-12 rounded-xl bg-gradient-to-br from-red-50 to-white border border-red-100/50 
                          flex items-center justify-center shadow-sm">
              <span className="text-lg font-semibold bg-gradient-to-r from-red-500 to-red-600 bg-clip-text text-transparent">
                {sectionNumber}
              </span>
            </div>
          </div>
  
          {/* Title */}
          <div className="flex-grow text-left">
            <h2 className="text-lg font-semibold text-gray-900 group-hover:text-red-600 transition-colors duration-300">
              {title}
            </h2>
            <p className="text-sm text-gray-500 mt-0.5">Click to {isOpen ? 'hide' : 'show'} details</p>
          </div>
  
          {/* Expand Icon */}
          <div className="relative flex-shrink-0">
            <div className={`w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center
                            transition-all duration-300 transform
                            ${isOpen ? 'rotate-180 bg-red-50' : 'rotate-0'}
                            group-hover:bg-red-50`}>
              <svg
                className={`w-5 h-5 transition-colors duration-300
                           ${isOpen ? 'text-red-500' : 'text-gray-400'}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
        </button>
  
        {/* Content Panel */}
        <div
          className={`transition-all duration-300 ease-in-out overflow-hidden
                      ${isOpen ? 'max-h-[2000px] opacity-100 mt-4' : 'max-h-0 opacity-0'}`}
        >
          <div className="p-6 bg-gradient-to-br from-red-50/30 to-white rounded-2xl border border-red-100/20">
            <div className="relative">
              {/* Decorative Elements */}
              <div className="absolute top-0 right-0 w-32 h-32 bg-red-50/50 rounded-full blur-xl -z-10"></div>
              <div className="absolute bottom-0 left-0 w-32 h-32 bg-red-100/30 rounded-full blur-xl -z-10"></div>
              
              {/* Content */}
              <div className="prose prose-lg max-w-none relative">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    p: ({children}) => (
                      <p className="text-gray-700 leading-relaxed mb-6 break-words whitespace-normal">{children}</p>
                    ),
                    strong: ({children}) => (
                      <strong className="font-medium text-red-600">{children}</strong>
                    ),
                    ul: ({children}) => (
                      <ul className="list-none space-y-3 text-gray-700 mb-6">{children}</ul>
                    ),
                    li: ({children}) => (
                      <li className="flex items-start gap-3 text-gray-700 mb-2 whitespace-normal">
                        <div className="w-1.5 h-1.5 rounded-full bg-red-400 mt-2.5 flex-shrink-0"></div>
                        <span>{children}</span>
                      </li>
                    ),
                    h3: ({children}) => (
                      <h3 className="flex items-center gap-2 text-gray-900 font-semibold text-lg mt-8 mb-4">
                        <div className="w-1 h-6 bg-gradient-to-b from-red-500 to-red-600 rounded-full"></div>
                        {children}
                      </h3>
                    )
                  }}
                >
                  {content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default AnalysisSection;