import React, { useState, useEffect, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { ChevronDown, AlertCircle, Loader2 } from 'lucide-react';

const BiomarkerChart = ({ data, biomarkerRanges, isLoading, error }) => {
  const hasData = data && data.length > 0;
  const hasRanges = biomarkerRanges && Object.keys(biomarkerRanges).length > 0;

  const availableBiomarkers = useMemo(() => {
    if (!hasRanges) return [];
    return Object.keys(biomarkerRanges).filter(name => 
      biomarkerRanges[name] && 
      !isNaN(biomarkerRanges[name].min) && 
      !isNaN(biomarkerRanges[name].max)
    );
  }, [biomarkerRanges, hasRanges]);

  const [selectedBiomarker, setSelectedBiomarker] = useState(availableBiomarkers[0] || '');

  useEffect(() => {
    if (availableBiomarkers.length > 0 && !availableBiomarkers.includes(selectedBiomarker)) {
      setSelectedBiomarker(availableBiomarkers[0]);
    }
  }, [availableBiomarkers, selectedBiomarker]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <Loader2 className="w-12 h-12 text-red-500 animate-spin" />
          <p className="mt-4 text-gray-600 font-medium">Loading your biomarkers...</p>
        </div>
      );
 }

    if (error) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-red-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">{error}</p>
        </div>
      );
    }

    if (!hasData || !hasRanges || availableBiomarkers.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">No biomarker data available</p>
        </div>
      );
    }

    const currentRange = biomarkerRanges[selectedBiomarker];
    if (!currentRange || !selectedBiomarker || isNaN(currentRange.min) || isNaN(currentRange.max)) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">Please select a valid biomarker</p>
        </div>
      );
    }
    
    const validData = data.filter(d => 
      d[selectedBiomarker] !== undefined && 
      d[selectedBiomarker] !== null && 
      !isNaN(d[selectedBiomarker])
    );

    if (validData.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <AlertCircle className="w-12 h-12 mb-4" />
          <p className="text-center">No valid data available for selected biomarker</p>
        </div>
      );
    }

    const minValue = Math.min(...validData.map(d => d[selectedBiomarker]));
    const maxValue = Math.max(...validData.map(d => d[selectedBiomarker]));
    const domainMin = Math.min(currentRange.min * 0.9, minValue * 0.9);
    const domainMax = Math.max(currentRange.max * 1.1, maxValue * 1.1);

    return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart 
            data={validData} 
            margin={{ 
              top: 20, 
              right: 35, // Reduced right margin
              left: 5,   // Reduced left margin
              bottom: 60 // Increased bottom margin for labels
            }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              stroke="#f1f5f9" 
              vertical={false} 
            />
            <XAxis 
              dataKey="date"
              tick={{ fontSize: 11, fill: '#64748b' }} // Slightly smaller font
              tickLine={{ stroke: '#cbd5e1' }}
              axisLine={{ stroke: '#cbd5e1' }}
              height={80} // Increased height for better label spacing
              tickMargin={25}
              angle={-45}
              interval="preserveStartEnd" // Shows first and last date always
            />
            <YAxis
              domain={[domainMin, domainMax]}
              label={{ 
                value: currentRange.unit, 
                angle: -90, 
                position: 'insideLeft',
                offset: 0, // Adjusted offset
                style: { 
                  fontSize: '12px',
                  fill: '#64748b',
                  fontWeight: 500,
                  padding: '0'
                }
              }}
              tick={{ 
                fontSize: 11, 
                fill: '#64748b' 
              }}
              axisLine={{ stroke: '#cbd5e1' }}
              tickLine={{ stroke: '#cbd5e1' }}
              width={40} // Fixed width for Y-axis
            />
            <Tooltip
              contentStyle={{ 
                backgroundColor: 'white', 
                border: 'none',
                borderRadius: '12px',
                boxShadow: '0 8px 16px -4px rgba(0, 0, 0, 0.1), 0 4px 8px -4px rgba(0, 0, 0, 0.06)',
                padding: '12px 16px'
              }}
              formatter={(value) => [
                `${value} ${currentRange.unit}`,
                selectedBiomarker
              ]}
              labelFormatter={(date) => `Date: ${date}`}
              labelStyle={{ 
                fontWeight: '600',
                color: '#334155',
                marginBottom: '4px'
              }}
            />
            <Legend 
              verticalAlign="top"
              height={36}
              wrapperStyle={{ 
                fontSize: '13px',
                fontWeight: 500,
                color: '#64748b',
                paddingTop: '12px'
              }} 
            />
            <ReferenceLine
              y={currentRange.min}
              stroke="#fca5a5"
              strokeDasharray="4 4"
              label={{ 
                value: "Min", 
                position: "right", 
                fontSize: '12px',
                fill: '#ef4444',
                fontWeight: 500
              }}
            />
            <ReferenceLine
              y={currentRange.max}
              stroke="#fca5a5"
              strokeDasharray="4 4"
              label={{ 
                value: "Max", 
                position: "right", 
                fontSize: '12px',
                fill: '#ef4444',
                fontWeight: 500
              }}
            />
            <Line
              type="monotone"
              dataKey={selectedBiomarker}
              stroke="#ef4444"
              strokeWidth={2.5}
              dot={{ 
                fill: '#fff',
                stroke: '#ef4444',
                strokeWidth: 2,
                r: 4
              }}
              activeDot={{ 
                r: 6,
                fill: "#ef4444",
                stroke: 'white',
                strokeWidth: 2
              }}
              name={selectedBiomarker}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    };
  
    return (
      <div className="w-full bg-white rounded-xl">
        <div className="p-6 border-b border-gray-100">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-3">
              <h2 className="text-lg font-bold text-gray-900">
                {selectedBiomarker || 'Select Biomarker'}
              </h2>
            </div>
            <div className="w-full sm:w-auto relative">
              <select
                value={selectedBiomarker}
                onChange={(e) => setSelectedBiomarker(e.target.value)}
                className="
                  appearance-none
                  w-full sm:w-64
                  px-4 py-2.5
                  pr-10
                  text-sm font-medium
                  text-gray-700
                  bg-white
                  border border-gray-200
                  rounded-xl
                  cursor-pointer
                  hover:border-red-100
                  focus:outline-none focus:border-red-200
                  transition-colors duration-200
                "
              >
                {availableBiomarkers.map(biomarker => (
                  <option key={biomarker} value={biomarker}>
                    {biomarker}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                <ChevronDown className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 sm:p-6 h-[400px] sm:h-[500px] overflow-x-auto"> {/* Added overflow-x-auto */}
          {renderContent()}
        </div>
      </div>
    );
  };
  
  export default BiomarkerChart;