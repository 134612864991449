import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import {auth, storage, db} from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ref, listAll, getMetadata, uploadBytes, getDownloadURL, updateMetadata, deleteObject } from "firebase/storage";
import BloodFlowLogoNL from "../../Assets/BloodflowLogoNoLetters.png";
import { toast } from 'react-toastify';
import MyBarChart from './BarGraph';
import CircularChart from './RadialGraph';
import axios from "axios"; 
import DeletePopup from './DeletePopup';
import NewFilePopup from './NewFilePopup';
import EditPersonalInfo from './EditPersonalInfo';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import BiomarkerChart from './BiomarkerChart';
import AnalysisSection, { parseAnalysisText } from './AnalysisSection';
import HealthScoreSection from './HealthScoreSection';
import { FileX2, Upload, PieChart, Trophy, TrendingUp,
     Activity, LineChart, Target, Heart,
     Brain, FileText, ChartBar, Inbox, CreditCard, Settings,
     LogOut, Plus, ChevronDown, Menu, X, Search, Camera} from 'lucide-react';
import UpgradeModal from './UpgradeModal';
import '../styles/animations.css';
//import SpatialVisualization from './SpatialVisualization';

function Dashboard() {

    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userAge, setUserAge] = useState(null);
    const [nav, setNav] = useState(false);
    const navigate = useNavigate();
    const [resultsVisible, setResultsVisible] = useState(true);
    const [userFiles, setUserFiles] = useState([]);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFilesForUpload, setSelectedFilesForUpload] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [fileScores, setFileScores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);
    const [analysisPerformed, setAnalysisPerformed] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    //const [screeningIndex, setScreeningIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showNewFileModal, setShowNewFileModal] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [loadingWindow, setLoadingWindow] = useState(true);
    const analysisContainerRef = useRef(null);
    const [smartReport, setSmartReport] = useState(null);
    const [smartReportLoading, setSmartReportLoading] = useState(false);
    const [chartBiomarkerLoading, setChartBiomarkerLoading] = useState(false);
    //const [analysisScreeningLoading, setAnalysisScreeningLoading] = useState(false);
    //const [screeningAnalysis, setScreeningAnalysis] = useState("");
    //const [screeningPerformed, setScreeningPerformed] = useState(false);
    //const [screeningResult, setScreeningResult] = useState("");
    const [activeTab, setActiveTab] = useState('fullAnalysis');
    const [biomarkerData, setBiomarkerData] = useState([]);
    const [biomarkerRanges, setBiomarkerRanges] = useState({});
    const [openSections, setOpenSections] = useState({});
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [profileImage, setProfileImage] = useState(null);

    const { t } = useTranslation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoadingWindow(false);
        }, 3000);
    
        return () => clearTimeout(timer);
      }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                const userData = await fetchUserData(user);
                if (userData.dateOfBirth) {
                    // fixed date of birth error
                    const dateOfBirth = new Date(userData.dateOfBirth.seconds * 1000);
                    const age = calculateUserAge(dateOfBirth);
                    setUserAge(age);
                }
            } else {
                setUser(null);
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate, userAge]);
    
    useEffect(() => {
        if (user) {
            fetchUserData(user);
        }
    }, [user]);

    useEffect(() => {
        const fetchProfileImage = async () => {
            if (user) {
                try {
                    const imageRef = ref(storage, `profileImages/${user.uid}`);
                    const url = await getDownloadURL(imageRef);
                    setProfileImage(url);
                } catch (error) {
                    console.log('No profile image found');
                }
            }
        };
        fetchProfileImage();
    }, [user]);
    
    const fetchUserData = async (user) => {
        try {
            const userId = user.uid;
            const userDocRef = doc(db, "users", userId);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                setUserData(userData);
                return userData;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        const fetchUserFiles = async () => {
            try {
                if (!user || !userData) return;
    
                const userId = auth.currentUser.uid;
                const userFilesRef = ref(storage, `bloodTestResults/${userId}`);
                const userFilesList = await listAll(userFilesRef);
    
                const filesWithMetadata = await Promise.all(userFilesList.items.map(async (item) => {
                    const metadata = await getMetadata(item);
                    return { name: item.name, time: metadata.timeCreated };
                }));
    
                const sortedFiles = filesWithMetadata.sort((a, b) => new Date(a.time) - new Date(b.time));
    
                setUserFiles(sortedFiles.map(file => file.name));
    
                if (sortedFiles.length === 0) {

                } else {
                    const scores = await Promise.all(sortedFiles.map(async (file) => {
                        const fileRef = ref(storage, `bloodTestResults/${userId}/${file.name}`);
                        const metadata = await getMetadata(fileRef);
                        return { time: metadata.timeCreated, score: parseFloat(metadata.customMetadata?.score || '0'), name: metadata.name };
                    }));
    
                    setFileScores(scores.filter(score => score !== null));
    
                    const latestFile = sortedFiles[sortedFiles.length - 1].name;
                    if (latestFile) {
                        handleFileClick(latestFile);
                    }
                }

                await fetchAllSmartReports(userId);
    
            } catch (error) {
                toast.error(`${t('ficheirosErroToast')} ${userData.username}!`);
            }
        };
    
        if (user) {
            fetchUserFiles();
        }
        // eslint-disable-next-line
    }, [user, userData]);

    const fetchUpdatedScores = async () => {
        try {
            const userId = auth.currentUser.uid;
            const userFilesRef = ref(storage, `bloodTestResults/${userId}`);
            const userFilesList = await listAll(userFilesRef);

            const filesWithMetadata = await Promise.all(userFilesList.items.map(async (item) => {
                const metadata = await getMetadata(item);
                return { name: item.name, time: metadata.timeCreated };
            }));

            const sortedFiles = filesWithMetadata.sort((a, b) => new Date(a.time) - new Date(b.time));

            setUserFiles(sortedFiles.map(file => file.name));

            if (sortedFiles.length === 0) {

            } else {
                const scores = await Promise.all(sortedFiles.map(async (file) => {
                    const fileRef = ref(storage, `bloodTestResults/${userId}/${file.name}`);
                    const metadata = await getMetadata(fileRef);
                    return { time: metadata.timeCreated, score: parseFloat(metadata.customMetadata?.score || '0'), name: metadata.name };
                }));

                setFileScores(scores.filter(score => score !== null));
            }

        } catch (error) {
            toast.error(t('erroResultadosToast'));
        }
    };

    const handleFileClick = useCallback (async (fileName) => {
        try {
            const userId = auth.currentUser.uid;
            const fileRef = ref(storage, `bloodTestResults/${userId}/${fileName}`);
    
            const metadata = await getMetadata(fileRef);
            setSelectedFileData(metadata);
    
            if (metadata.customMetadata?.analysis !== undefined) {
                setDisplayedText(metadata.customMetadata.analysis);
                setAnalysisPerformed(true);
            } else {
                setAnalysisPerformed(false);
            }

            /*
            if (metadata.customMetadata?.screening !== undefined) {
                setScreeningAnalysis(metadata.customMetadata.screening);
                setScreeningPerformed(true);
            } else {
                setScreeningPerformed(false);
                setScreeningAnalysis("");
            }
            */
    
            try {
                const smartReport = await fetchSmartReport(userId, fileName);
                setSmartReport(smartReport);
                await fetchAllSmartReports(userId);
            } catch (error) {
                console.warn("Failed to fetch Smart Report:", error);
                setSmartReport(null);
            }

        } catch (error) {
            console.error("Error handling file click:", error);
            toast.error(t('erroFicheiroSelecionado'));
        }
    }, [t]);

    const saveSmartReportToStorage = async (smartReport, userId, fileName) => {
        const smartReportRef = ref(storage, `smartReportsJson/${userId}/${fileName}_smartReport.json`);
        
        const jsonData = JSON.stringify(smartReport);
        const blob = new Blob([jsonData], { type: "application/json" });
      
        return uploadBytes(smartReportRef, blob);
    };

    const fetchSmartReport = async (userId, fileName) => {
        try {
            const smartReportRef = ref(storage, `smartReportsJson/${userId}/${fileName}_smartReport.json`);
    
            try {
                await getMetadata(smartReportRef);
            } catch (error) {
                if (error.code === 'storage/object-not-found') {
                    console.warn('Smart Report file does not exist.');
                    return;
                }
                throw error;
            }
    
            const downloadURL = await getDownloadURL(smartReportRef);
            const response = await fetch(downloadURL);
            const smartReport = await response.json();
    
            return smartReport;
        } catch (error) {
            console.log('Failed to fetch Smart Report:', error);
            return null;
        }
    };

    const fetchAllSmartReports = async (userId) => {
        try {
            const folderRef = ref(storage, `smartReportsJson/${userId}`);
            const filesList = await listAll(folderRef);
            
            const allData = {};
            const ranges = {};
    
            setChartBiomarkerLoading(true);
    
            const filesWithMetadata = await Promise.all(
                filesList.items.map(async (file) => {
                    if (!file.name.endsWith('_smartReport.json')) return null;
                    const metadata = await getMetadata(file);
                    return {
                        file,
                        metadata,
                        timeCreated: metadata.timeCreated
                    };
                })
            );
    
            const sortedFiles = filesWithMetadata
                .filter(item => item !== null)
                .sort((a, b) => new Date(a.timeCreated) - new Date(b.timeCreated));
    
            await Promise.all(sortedFiles.map(async ({ file }) => {
                const downloadURL = await getDownloadURL(file);
                const response = await fetch(downloadURL);
                const smartReport = await response.json();
                
                if (smartReport && Array.isArray(smartReport.parameters)) {
                    smartReport.parameters.forEach(param => {
                        if (!param.name || !param.value || !param.range) return;
    
                        if (!allData[param.name]) {
                            allData[param.name] = [];
                        }
                        
                        const valueNum = parseFloat(param.value.split(' ')[0]);
                        if (!isNaN(valueNum)) {
                            allData[param.name].push(valueNum);
                        }
                        
                        if (!ranges[param.name] && param.range) {
                            const rangeParts = param.range.split('-');
                            if (rangeParts.length === 2) {
                                const min = parseFloat(rangeParts[0]);
                                const max = parseFloat(rangeParts[1]);
                                if (!isNaN(min) && !isNaN(max) && min < max) {
                                    ranges[param.name] = {
                                        min,
                                        max,
                                        unit: param.value.split(' ').slice(1).join(' ')
                                    };
                                }
                            }
                        }
                    });
                }
            }));
    
            const transformedData = [];
            
            // Process each file's data with its date
            sortedFiles.forEach(({ metadata }, index) => {
                const dataPoint = {
                    index: index + 1,
                    date: new Date(metadata.timeCreated).toLocaleDateString(),
                };
                Object.keys(allData).forEach(biomarker => {
                    if (allData[biomarker][index] !== undefined) {
                        dataPoint[biomarker] = allData[biomarker][index];
                    }
                });
                transformedData.push(dataPoint);
            });
    
            if (Object.keys(ranges).length > 0 && Object.keys(allData).length > 0) {
                setBiomarkerData(transformedData);
                setBiomarkerRanges(ranges);
            }
    
            setChartBiomarkerLoading(false);
    
        } catch (error) {
            console.error('Failed to fetch smart reports:', error);
            setBiomarkerData([]);
            setBiomarkerRanges({});
            setChartBiomarkerLoading(false);
        }
    };

    const handleAnalyzeClick = async () => {
        setLoading(true);
        try {
            const userId = auth.currentUser.uid;
            const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);

            try {
                const metadata = await getMetadata(fileRef);
                setSelectedFileData(metadata);

                if(metadata.customMetadata?.analysis !== undefined) {
                    setDisplayedText(metadata.customMetadata.analysis);
                    setLoading(true);
                    setTimeout(() => {
                        setAnalysisPerformed(true);
                        analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 800);
                    return;
                }

                /*if(metadata.customMetadata?.screening !== undefined) {
                    setScreeningAnalysis(metadata.customMetadata.screening);
                    setAnalysisScreeningLoading(true);
                    setTimeout(() => {
                        setScreeningPerformed(true);
                        analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 800);
                    return;
                }*/
            } catch (error) {
                console.error('Error fetching file metadata:', error);
            }
        } catch (error) {
            console.error('Error fetching file metadata:', error);
        } finally {
            setLoading(false);
            //setAnalysisScreeningLoading(false);
        }

        setLoading(true);
        //setAnalysisScreeningLoading(true);
        setTimeout(() => {
            setAnalysisPerformed(true);
            //setScreeningPerformed(true);
            analysisContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 1200);

        try {
            const userId = auth.currentUser.uid;
        
            const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);
            const downloadURL = await getDownloadURL(fileRef);
            const text = await convertPDFToText(downloadURL);

            try {
                // Make a lightweight check request first
                const checkResponse = await fetch(`${BASE_URL}/check-analysis-count`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userID: userId,
                    }),
                });
    
                if (checkResponse.status === 403) {
                    setShowUpgradeModal(true);
                    setAnalysisPerformed(false);
                    setLoading(false);
                    return; // Stop here if no more analyses available
                }
            } catch (error) {
                console.error('Error checking analysis count:', error);
            }
        
            setLoading(true); 
            const fullAnalysisPromise = analyseBloodTest(text)
                .then((fullAnalysisResult) => {
                    setLoading(false);
                    setDisplayedText(fullAnalysisResult);
        
                    if (fullAnalysisResult) {
                        const score = extractScoreFromAnalysis(fullAnalysisResult);
                        setScores([...scores, { time: new Date().toLocaleDateString(), score }]);
        
                        getMetadata(fileRef).then((fileMetadata) => {
                            updateMetadata(fileRef, {
                                customMetadata: {
                                    ...fileMetadata.customMetadata,
                                    score: parseFloat(score),
                                    analysis: fullAnalysisResult,
                                },
                            }).catch((error) => console.error("Error updating metadata:", error));
                        });
                        fetchUpdatedScores();
                    } else {
                        console.warn("Full analysis failed.");
                    }
                })
                .catch((error) => {
                    console.error("Error in full analysis:", error);
                    setLoading(false); 
                });

                /*setAnalysisScreeningLoading(true); 
                const screeningAnalysisPromise = analyseBloodTestForScreening(text)
                    .then((screeningAnalysisPromise) => {
                        setAnalysisScreeningLoading(false);
                        setScreeningResult(screeningAnalysisPromise);

                        if (screeningAnalysisPromise) {
                            getMetadata(fileRef).then((fileMetadata) => {
                                updateMetadata(fileRef, {
                                    customMetadata: {
                                        ...fileMetadata.customMetadata,
                                        screening: screeningAnalysisPromise,
                                    },
                                }).catch((error) => console.error("Error updating metadata:", error));
                            });
                        } else {
                            console.warn("Screening analysis failed.");
                        }
                    })
                    .catch((error) => {
                        console.error("Error in screening analysis:", error);
                        setAnalysisScreeningLoading(false); 
                    });*/
        
            setSmartReportLoading(true);
            const smartReportPromise = generateSmartReport(text)
                .then(async (smartReportResult) => {
                    if (smartReportResult) {
                        await saveSmartReportToStorage(smartReportResult, userId, selectedFileData.name);
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        await fetchAllSmartReports(userId);
                        
                        setSmartReport(smartReportResult);
                    } else {
                        console.warn("Smart report failed.");
                    }
                    setSmartReportLoading(false);
                })
                .catch((error) => {
                    console.error("Error in smart report:", error);
                    setSmartReportLoading(false);
                });
        
            await Promise.all([fullAnalysisPromise, smartReportPromise]);

        } catch (error) {
            if (error.response?.status === 403 || error.status === 403) {
                setShowUpgradeModal(true);
                setAnalysisPerformed(false);
            } else {
                toast.error(t('erroAnalise'));
            }
            setAnalysisPerformed(false);
        } finally {
            setLoading(false);
            setSmartReportLoading(false);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); 
        setSelectedFilesForUpload((prevFiles) => [...prevFiles, ...files]);
        setUploadSuccess(false);
        setShowNewFileModal(true);
    };
    
    const handleUpload = async () => {
        try {
            if (selectedFilesForUpload.length === 0) {
                toast.error(t('selecioneFicheiro'));
                return;
            }

            for(const file of selectedFilesForUpload) {
                const userId = auth.currentUser.uid;
                const storageRef = ref(storage, `bloodTestResults/${userId}/${file.name}`);
                await uploadBytes(storageRef, file);   
            }

            setUploadSuccess(true);
            setShowNewFileModal(false);
            setSelectedFilesForUpload([]);
            
            toast.success(t('ficheiroCarregado'));
            window.location.reload();
        } catch (error) {
            toast.error(t('erroFicheiroAgain'))
        }
    };

    const handleNewFileClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.pdf';
        input.multiple = true;
        input.onchange = handleFileChange;
        input.click();
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            toast.success(t('logout'));
            navigate('/');
        } catch (error) {
            toast.error(t('logoutError'));
        }
    };

    const handleSideBar = () => {
        setNav(!nav);
    };

    useEffect(() => {
        const closeBarOnResize = () => {
            if (window.innerWidth > 768 && nav) {
                setNav(false);
            }
        };

        window.addEventListener('resize', closeBarOnResize);

        return () => {
            window.removeEventListener('resize', closeBarOnResize);
        };
    }, [nav]);

    const toggleResults = () => {
        setResultsVisible(!resultsVisible);
    }

    const convertPDFToText = async (pdfURL) => {
        try {
            const response = await fetch(`${BASE_URL}/extract-text`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ pdfURL }),
              });

              if (response.status === 400) {
                const { message } = await response.json();
                const password = prompt(message);
                
                if (password) {
                    const unlockResponse = await fetch(`${BASE_URL}/submit-password`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ pdfURL, password }),
                    });
    
                    if (unlockResponse.ok) {
                        const unlockData = await unlockResponse.json();
                        return unlockData.extractedText;
                    } else {
                        console.error('Failed to unlock PDF:', unlockResponse.statusText);
                        throw new Error('Failed to unlock PDF');
                    }
                } else {
                    throw new Error('Password input was canceled');
                }
              }
      
              const data = await response.json();
              return data.text;
            } catch (error) {
              console.error('Error fetching text:', error);
            }
    };

    function getLanguageFromDomain() {
        const domain = window.location.hostname;
  
        if (domain === 'bloodflow.eu' || domain === 'www.bloodflow.eu') {
            return 'en';
        } else if (domain === 'bloodflow.pt' || domain === 'www.bloodflow.pt') {
            return 'pt';
        }

        return 'en';
    }

    const analyseBloodTest = async (text) => {
        const language = getLanguageFromDomain();
        const userName = userData.username;
        const age = userAge;
        const medicalCondition = userData.medicalCondition;
        const userID = user.uid;
        const analysisType = 'full';
    
        try {

            if(language === 'en') {
                const response = await axios.post(`${BASE_URL}/analyze-blood-test`, {
                    text,
                    userName,
                    age,
                    medicalCondition,
                    userID,
                    analysisType,
                    
                });
        
                return response.data.analysis;

            } else {
                const response = await axios.post(`${BASE_URL}/analyze-blood-test-pt`, {
                    text,
                    userName,
                    age,
                    medicalCondition,
                    userID,
                    analysisType,
                    
                });
        
                return response.data.analysis;
            }

        } catch (error) {
            throw error; 
        }
    };

    /*
    const analyseClinicaDataJcs = async (text) => {

        try {
            const response = await axios.post(`/api/jcs/analyse-test-jcs`, {
                text,
            });

            return response.data.analysis;
        } catch (error) {
            throw error;
        }
    };
    */

    /*
    const analyseBloodTestForScreening = async (text) => {
        const language = getLanguageFromDomain();
        const userName = userData.username;
        const age = userAge;
        const medicalCondition = userData.medicalCondition;
        const userID = user.uid;

        try {
            const response = await axios.post(`${BASE_URL}/analyze-blood-test-screening`, {
                text,
                userName,
                age,
                medicalCondition,
                language,
                userID,
            });

            return response.data.analysis;
        } catch (error) {
            throw error;
        }
    };
    */

    const generateSmartReport = async (text) => {
        try {
            const language = getLanguageFromDomain();
            const userID = user.uid;
            const analysisType = 'smart';
    
            const response = await fetch(`${BASE_URL}/generate-smart-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text, language, userID, analysisType }),
            });
    
            if (!response.ok) {
                console.error('Failed to generate smart report');
                return null;
            }
    
            const data = await response.json();
            return data.analysisSmartReport;
    
        } catch (error) {
            console.error('Error generating smart report:', error);
            return null;
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const extractScoreFromAnalysis = (analysis) => {
        const match = analysis.match(/(?:\*\*)?Score(?:\*\*)?:\s*(\d+(\.\d+)?)/i);
        return match ? parseFloat(match[1]) : 0;
    };

    /*
    useEffect(() => {
        if (screeningResult && screeningIndex < screeningResult.length) {
            const intervalId = setInterval(() => {
                setScreeningAnalysis((prev) => prev + screeningResult.charAt(screeningIndex));
                setScreeningIndex((prev) => prev + 1);
            }, 10);

            if (screeningIndex === screeningResult.length) {
                clearInterval(intervalId);
            }

            return () => clearInterval(intervalId);
        }
    }, [screeningIndex, screeningResult]);
    */


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleDeleteClick = () => { 
        setShowModal(true);
    };

    const selectAnotherFile = (files) => {
        if (files.length > 0) {
            const fileName = files[files.length - 1];
            handleFileClick(fileName);
        } else {
            setSelectedFileData(null);
        }
    };  

    const handleCancelFileUpload = () => {  
        setSelectedFile(null);
        setUploadSuccess(false);
        setShowNewFileModal(false);
    };    

    const confirmDeleteFile = async () => {
        const userId = user.uid;
        const fileRef = ref(storage, `bloodTestResults/${userId}/${selectedFileData.name}`);
        const fileRefSmartReport = ref(storage, `smartReportsJson/${userId}/${selectedFileData.name}_smartReport.json`);

        try {
            await deleteObject(fileRef);
            await deleteObject(fileRefSmartReport);
            toast.success(t('ficheiroEliminado'));
            setUserFiles((prevFiles) => prevFiles.filter(file => file !== selectedFileData.name));
            setFileScores((prevScores) => prevScores.filter(file => file.name !== selectedFileData.name));
            await fetchAllSmartReports(userId);
            selectAnotherFile(userFiles.filter(file => file !== selectedFileData.name));
            setShowModal(false);
        } catch (error) {
            toast.error(t('ficheiroEliminadoError'));
        }
      };

    const handleEditClick = () => {
        setShowEditPopup(true);
    };
    
    const handleClosePopup = () => {
        setShowEditPopup(false);
    };

    const calculateUserAge = (dateOfBirth) => {
        const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        
        const monthDiff = today.getMonth() - dateOfBirth.getMonth();
        const dayDiff = today.getDate() - dateOfBirth.getDate();

        if(monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    }

    const handleProfileImageChange = async (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            try {
                const imageRef = ref(storage, `profileImages/${user.uid}`);
                await uploadBytes(imageRef, file);
                const url = await getDownloadURL(imageRef);
                setProfileImage(url);
                toast.success(t('profileImageUpdated'));
            } catch (error) {
                toast.error(t('errorUploadingImage'));
            }
        }
    };

    return (
        loadingWindow ? (
            <Spinner />
        ) : (
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar - Desktop & Mobile */}
                <aside className={`
                    fixed inset-y-0 left-0 z-50 
                    w-[280px] bg-white border-r border-gray-100
                    transform transition-transform duration-300 ease-in-out
                    ${nav ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
                `}>
                    <div className="flex flex-col h-full">
                        {/* Logo Section */}
                        <div className="p-4 -mb-4">
                            <button 
                                onClick={handleSideBar}
                                className="md:hidden px-2 hover:bg-gray-100 rounded-lg"
                            >
                                <X className="h-6 w-6 text-gray-500" />
                            </button>
                        </div>
    
                        {userData && (
                            <div className="p-2 border-b border-gray-100">
                                <div className="bg-gradient-to-br from-white via-red-50 to-white rounded-xl p-3 relative overflow-hidden min-h-[280px]">
                                    {/* Decorative Elements */}
                                    <div className="absolute inset-0">
                                        <div className="absolute -right-4 -top-4 w-32 h-32 bg-red-100/50 rounded-full blur-xl"></div>
                                        <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-red-50/50 rounded-full blur-xl"></div>
                                        <div className="absolute inset-0 bg-[linear-gradient(125deg,rgba(255,255,255,0.3),rgba(255,255,255,0.2))] backdrop-blur-sm"></div>
                                    </div>

                                    <div className="relative space-y-4">
                                        {/* Credit Card Style Header */}
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center gap-3">
                                                <div className="relative group">
                                                    <div className="h-12 w-12 rounded-xl bg-gradient-to-r from-red-500 to-red-600 flex items-center justify-center ring-2 ring-white shadow-lg overflow-hidden">
                                                        {profileImage ? (
                                                            <img 
                                                                src={profileImage} 
                                                                alt={userData.username} 
                                                                className="w-full h-full object-cover"
                                                            />
                                                        ) : (
                                                            <span className="text-base font-bold text-white">
                                                                {userData.username[0].toUpperCase()}
                                                            </span>
                                                        )}
                                                        
                                                        <label className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-200 cursor-pointer flex items-center justify-center">
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={handleProfileImageChange}
                                                                className="hidden"
                                                            />
                                                            <Camera className="w-5 h-5 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h3 className="text-base font-bold text-gray-900">{userData.username}</h3>
                                                    <div className="flex items-center gap-1.5 text-sm text-gray-600">
                                                        <span>{userAge} {t('Anos')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`px-3 py-1 rounded-full text-xs font-medium 
                                                ${userData.subscriptionStatus === 'pro' 
                                                    ? 'bg-gradient-to-r from-red-500 to-red-600 text-white shadow-md' 
                                                    : 'bg-gray-100 text-gray-600'
                                                }`}>
                                                {userData.subscriptionStatus === 'pro' ? 'Pro' : 'Free'}
                                            </div>
                                        </div>

                                        {/* Credit Card Style Stats */}
                                        <div className="grid grid-cols-2 gap-3 mt-3">
                                            <div className="bg-white bg-opacity-70 backdrop-blur-sm rounded-xl p-2 border border-gray-100/50 shadow-sm">
                                                <div className="flex items-center gap-2 mb-2">
                                                    <div className="p-1.5 bg-red-50 rounded-lg">
                                                        <Activity className="w-4 h-4 text-red-500" />
                                                    </div>
                                                    <span className="text-xs font-medium text-gray-700">{t('medStatus')}</span>
                                                </div>
                                                <p className="text-sm text-gray-900 font-medium truncate">
                                                    {userData.medicalCondition || t('nenhuma')}
                                                </p>
                                            </div>

                                            <div className="bg-white bg-opacity-70 backdrop-blur-sm rounded-xl p-2 border border-gray-100/50 shadow-sm">
                                                <div className="flex items-center gap-2 mb-2">
                                                    <div className="p-1.5 bg-red-50 rounded-lg">
                                                        <FileText className="w-4 h-4 text-red-500" />
                                                    </div>
                                                    <span className="text-xs font-medium text-gray-700">{t('Reports')}</span>
                                                </div>
                                                <p className="text-sm text-gray-900 font-medium">
                                                    {userFiles.length} {t('files')}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Analysis Progress - Only for Free Users */}
                                        {userData?.subscriptionStatus !== 'pro' && (
                                            <div className="mt-4">
                                                <div className="bg-white bg-opacity-70 backdrop-blur-sm rounded-xl p-2 border border-gray-100/50 shadow-sm">
                                                    <div className="flex items-center justify-between mb-3">
                                                        <div className="flex items-center gap-2">
                                                            <CreditCard className="w-4 h-4 text-red-500" />
                                                            <span className="text-sm font-medium text-gray-700">{t('AnalysisCredits')}</span>
                                                        </div>
                                                        <span className="text-[10px] px-2 py-0.5 rounded-full bg-red-50 text-red-600 font-medium">
                                                            {5 - (userData?.analysisCount || 0)} {t('remaining')}
                                                        </span>
                                                    </div>
                                                    <div className="relative">
                                                        <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                                                            <div 
                                                                className="h-full bg-gradient-to-r from-red-500 to-red-600 transition-all duration-500"
                                                                style={{ width: `${((5 - (userData?.analysisCount || 0)) / 5) * 100}%` }}
                                                            ></div>
                                                        </div>
                                                        <div className="flex items-center justify-between mt-3">
                                                            <div className="flex items-center gap-1">
                                                                <ChartBar className="w-3.5 h-3.5 text-gray-400" />
                                                                <span className="text-xs text-gray-500">{t('FreePlan')}</span>
                                                            </div>
                                                            <button 
                                                                onClick={() => navigate('/dashboard/subscription')}
                                                                className="flex items-center gap-1.5 px-3 py-1.5 rounded-lg bg-gradient-to-r from-red-500 to-red-600 text-white text-xs font-medium hover:from-red-600 hover:to-red-700 transition-all duration-200 shadow-sm"
                                                            >
                                                                {t('UpgradetoPro')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
    
                        {/* Files Section */}
                        <div className="flex-1 overflow-y-auto p-4">
                            <div className="space-y-4">
                                <button 
                                    onClick={toggleResults}
                                    className="w-full flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
                                >
                                    <div className="flex items-center">
                                        <FileText className="w-4 h-4 text-gray-400" />
                                        <span className="ml-2 text-sm font-medium text-gray-600">
                                            {t('resultsStored')}
                                        </span>
                                    </div>
                                    <ChevronDown 
                                        className={`w-4 h-4 text-gray-400 transition-transform duration-200 
                                            ${resultsVisible ? 'rotate-180' : ''}`}
                                    />
                                </button>
    
                                {resultsVisible && (
                                    <div className="space-y-1">
                                        {userFiles.length > 0 ? (
                                            userFiles.slice().reverse().map((file, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleFileClick(file)}
                                                    className={`
                                                        w-full text-left px-3 py-2 rounded-lg text-sm
                                                        ${selectedFileData?.name === file 
                                                            ? 'bg-red-50 text-red-700 font-medium' 
                                                            : 'text-gray-600 hover:bg-gray-50'
                                                        }
                                                    `}
                                                >
                                                    {file}
                                                </button>
                                            ))
                                        ) : (
                                            <div className="text-center py-6">
                                                <div className="inline-flex p-3 rounded-full bg-red-50 mb-3">
                                                    <Inbox className="w-6 h-6 text-red-500" />
                                                </div>
                                                <p className="text-sm text-gray-500">{t('noStoredResults')}</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
    
                        {/* Bottom Actions */}
                        <div className="p-4 border-t border-gray-100">
                            <div className="space-y-2">
                                <button
                                    onClick={handleNewFileClick}
                                    className="w-full flex items-center justify-center px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-700"
                                >
                                    <Plus className="w-4 h-4 mr-2" />
                                    <span className="text-sm font-medium">{t('newFile')}</span>
                                </button>
    
                                <button
                                    onClick={handleLogout}
                                    className="w-full flex items-center justify-center px-4 py-2 rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200"
                                >
                                    <LogOut className="w-4 h-4 mr-2" />
                                    <span className="text-sm font-medium">{t('logoutDash')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </aside>
    
                {/* Main Content */}
                <main className=" flex-1 md:ml-[280px] flex flex-col h-screen">
                    {/* Header */}
                    <header className="stagger-animation stagger-1 sticky top-0 z-40 bg-white border-b border-gray-100">
                        <div className="flex h-16 items-center justify-between px-4">
                            <div className="flex items-center gap-4">
                                <button
                                    onClick={handleSideBar}
                                    className="md:hidden p-2 hover:bg-gray-100 rounded-lg"
                                >
                                    <Menu className="h-6 w-6 text-gray-500" />
                                </button>
                                <h1 className="text-xl font-semibold text-gray-900">
                                    {t('dasboardTitle')}
                                    <span className="text-red-600">{userData?.username}</span>
                                    <h1 className="text-sm text-black font-light">
                                        {t('dashboardSubtitle')}
                                    </h1>
                                </h1>
                            </div>
    
                            <div className="flex items-center space-x-4">
                                <button 
                                    onClick={handleEditClick}
                                    className="p-2 hover:bg-gray-100 rounded-lg"
                                >
                                    <Settings className="w-5 h-5 text-gray-500" />
                                </button>
                            </div>
                        </div>
                    </header>
    
                    {/* Scrollable Content Area */}
                    <div className="flex-1 overflow-y-auto bg-gray-50">
                        {/* Selected File Container */}
                        <div className="stagger-animation stagger-2 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden ">
                            {/* Decorative Elements */}
                            <div className="absolute inset-0">
                                <div className="absolute -right-4 -top-4 w-32 h-32 bg-red-100/50 rounded-full blur-xl"></div>
                                <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-red-50/50 rounded-full blur-xl"></div>
                                <div className="absolute inset-0 bg-[linear-gradient(125deg,rgba(255,255,255,0.3),rgba(255,255,255,0.2))] backdrop-blur-sm"></div>
                            </div>

                            {selectedFileData && selectedFile == null ? (
                                <div className="relative">
                                    <div className="flex flex-col md:flex-row gap-6">
                                        {/* File Info Section */}
                                        <div className="w-full md:w-2/3">
                                            <div className="h-full bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                                <div className="flex items-center justify-between mb-6">
                                                    <div className="flex items-center gap-3">
                                                        <div className="p-2.5 bg-red-50 rounded-xl">
                                                            <FileText className="w-6 h-6 text-red-500" />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-bold text-gray-900">{t('selectedFileForAnalysis')}</h3>
                                                            <p className="text-sm text-gray-600">{selectedFileData.name}</p>
                                                        </div>
                                                    </div>
                                                    <span className="text-xs px-3 py-1.5 rounded-full bg-red-50 text-red-600 font-medium">
                                                        PDF
                                                    </span>
                                                </div>

                                                <div className="space-y-4">
                                                    <div className="flex items-center gap-3">
                                                        <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                                        <p className="text-sm text-gray-700">
                                                            <span className="font-medium">{t('storedAt')}</span>
                                                            <span className="ml-2">{formatDate(selectedFileData.timeCreated)}</span>
                                                        </p>
                                                    </div>
                                    
                                                    <div className="pt-2">
                                                        <p className="text-xs text-gray-500 flex items-center gap-2 font-medium">
                                                            <svg className="w-4 h-4 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            {t('textFileSelected')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Analysis Actions Section */}
                                        <div className="w-full md:w-1/3">
                                            <div className="stagger-animation stagger-3 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                                <div className="flex items-center gap-3 mb-6">
                                                    <div className="p-2.5 bg-red-50 rounded-xl">
                                                        <Brain className="w-6 h-6 text-red-500" />
                                                    </div>
                                                    <span className="text-lg font-bold text-gray-900">{t('startAnalysis')}</span>
                                                </div>

                                                <div className="space-y-3">
                                                    <button 
                                                        onClick={handleAnalyzeClick} 
                                                        disabled={loading}
                                                        className="w-full flex items-center justify-center gap-2 px-4 py-3.5 rounded-xl bg-gradient-to-r from-red-500 to-red-600 text-white hover:from-red-600 hover:to-red-700 transition-all duration-300 shadow-sm disabled:opacity-75 transform hover:-translate-y-0.5"
                                                    >
                                                        {loading ? (
                                                            <>
                                                                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                                                </svg>
                                                                <span className="text-sm font-medium">{t('analysing')}</span>
                                                            </>
                                                        ) : (
                                                            <span className="text-sm font-medium">{t('analise')}</span>
                                                        )}
                                                    </button>

                                                    <button 
                                                        onClick={handleDeleteClick}
                                                        className="w-full flex items-center justify-center gap-2 px-4 py-3.5 rounded-xl bg-gray-800 text-white hover:bg-black transition-all duration-300 shadow-sm transform hover:-translate-y-0.5"
                                                    >
                                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                        <span className="text-sm font-medium">{t('deleteButton')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative p-8 text-center">
                                    <div className="flex flex-col items-center gap-6">
                                        <div className="relative">
                                            <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
                                            <div className="relative bg-white p-4 rounded-xl shadow-sm border border-red-100">
                                                <FileX2 className="w-8 h-8 text-red-500" />
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-900 mb-2">
                                                {t('selectedFileForAnalysis')}
                                            </h2>
                                            <p className="text-gray-600">{t('noData')}</p>
                                        </div>
                                        
                                        <div className="flex items-center gap-2 text-sm text-gray-500">
                                            <Upload className="w-4 h-4" />
                                            <span>{t('upFile')}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Delete Confirmation Modal */}
                        {showModal && (
                                <DeletePopup
                                    show={showModal}
                                    onClose={() => setShowModal(false)}
                                    onConfirm={confirmDeleteFile}
                                >
                                </DeletePopup>
                        )}

                        {/* Test Score Container */}
                        <div className="stagger-animation stagger-3 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden">
                            {/* Decorative Elements */}
                            <div className="absolute inset-0">
                                <div className="absolute -right-4 -top-4 w-32 h-32 bg-red-100/50 rounded-full blur-xl"></div>
                                <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-red-50/50 rounded-full blur-xl"></div>
                                <div className="absolute inset-0 bg-[linear-gradient(125deg,rgba(255,255,255,0.3),rgba(255,255,255,0.2))] backdrop-blur-sm"></div>
                            </div>

                            {selectedFileData && selectedFile == null ? (
                                <div className="relative">
                                    <div className="flex flex-col md:flex-row gap-6">
                                        {/* Circular Chart Section */}
                                        <div className="w-full md:w-1/3">
                                            <div className="h-full bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                                <div className="flex flex-col h-full justify-between">
                                                    {/* Header */}
                                                    <div className="flex items-center gap-3 mb-6">
                                                        <div className="p-2.5 bg-red-50 rounded-xl">
                                                            <PieChart className="w-6 h-6 text-red-500" />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-bold text-gray-900">Latest Health Score</h3>
                                                        </div>
                                                    </div>

                                                    {/* Chart - Centered vertically */}
                                                    <div className="flex-1 flex flex-col items-center justify-center">
                                                        <CircularChart 
                                                            score={fileScores.length > 0 
                                                                ? fileScores[fileScores.length - 1].score 
                                                                : fileScores[fileScores.length - 2].score
                                                            } 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Bar Chart Section */}
                                        <div className="max-w-full md:w-2/3">
                                            <div className="h-full w-full max-w-full bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                                <div className="flex items-center justify-between mb-6">
                                                    <div className="flex items-center gap-3">
                                                        <div className="p-2.5 bg-red-50 rounded-xl">
                                                            <ChartBar className="w-6 h-6 text-red-500" />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-bold text-gray-900">Score Evolution</h3>
                                                            <p className="text-sm text-gray-600">Track your progress over time</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="h-[400px] w-full max-w-full overflow-hidden">
                                                    <div className="w-full h-full">
                                                        <MyBarChart 
                                                            data={fileScores}
                                                            selectedFileMetadata={selectedFileData}
                                                            onBarClick={handleFileClick}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative p-8 text-center">
                                    <div className="flex flex-col items-center gap-6">
                                        <div className="relative">
                                            <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
                                            <div className="relative bg-white p-4 rounded-xl shadow-sm border border-red-100">
                                                <Trophy className="w-8 h-8 text-red-500" />
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-900 mb-2">
                                                {t('scoreProgress')}
                                            </h2>
                                            <p className="text-gray-600">Upload your first report to see your health score</p>
                                        </div>
                                        
                                        <div className="flex items-center gap-4">
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <PieChart className="w-6 h-6 text-red-500/70" />
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <ChartBar className="w-6 h-6 text-red-500/70" />
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <TrendingUp className="w-6 h-6 text-red-500/70" />
                                            </div>
                                        </div>

                                        <div className="max-w-sm mx-auto">
                                            <p className="text-gray-600 mb-2">{t('noData')}</p>
                                            <p className="text-sm text-gray-500">{t('completeAnalysis')}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Biomarker Tracking Chart */}
                        <div className="stagger-animation stagger-4 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden">
                            {/* Decorative Elements */}
                            <div className="absolute inset-0">
                                <div className="absolute -right-4 -top-4 w-32 h-32 bg-red-100/50 rounded-full blur-xl"></div>
                                <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-red-50/50 rounded-full blur-xl"></div>
                                <div className="absolute inset-0 bg-[linear-gradient(125deg,rgba(255,255,255,0.3),rgba(255,255,255,0.2))] backdrop-blur-sm"></div>
                            </div>

                            {selectedFileData && selectedFile == null ? (
                                <div className="relative">
                                    <div className="flex flex-col gap-6">
                                        <div className="w-full">
                                            <div className="h-full bg-white p-4 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                                {/* Header */}
                                                <div className="flex items-center justify-between mb-6">
                                                    <div className="flex items-center gap-3">
                                                        <div className="p-2.5 bg-red-50 rounded-xl">
                                                            <Activity className="w-6 h-6 text-red-500" />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-lg font-bold text-gray-900">Biomarker Tracking</h3>
                                                            <p className="text-sm text-gray-600">Monitor your biomarkers over time</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Chart - Increased height */}
                                                <div className="w-full h-full max-w-full overflow-hidden">
                                                    <BiomarkerChart
                                                        data={biomarkerData}
                                                        biomarkerRanges={biomarkerRanges}
                                                        isLoading={chartBiomarkerLoading}
                                                        error={null}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="relative">
                                    <div className="flex flex-col items-center gap-6 p-8">
                                        <div className="relative">
                                            <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
                                            <div className="relative bg-white p-4 rounded-xl shadow-sm border border-red-100">
                                                <Activity className="w-8 h-8 text-red-500" />
                                            </div>
                                        </div>

                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-900 mb-2">Biomarker Tracking</h2>
                                            <p className="text-gray-600">Track your biomarkers evolution over time</p>
                                        </div>

                                        <div className="grid grid-cols-4 gap-4">
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <Activity className="w-6 h-6 text-red-500/70" />
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <LineChart className="w-6 h-6 text-red-500/70" />
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <Target className="w-6 h-6 text-red-500/70" />
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-xl border border-gray-100">
                                                <Heart className="w-6 h-6 text-red-500/70" />
                                            </div>
                                        </div>

                                        <div className="max-w-sm mx-auto text-center">
                                            <p className="text-gray-600 mb-2">{t('noData')}</p>
                                            <p className="text-sm text-gray-500">{t('upFileBio')}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Analysis Results Container */}
                        <div
                            id="analysisContainer"
                            ref={analysisContainerRef}
                            className="stagger-animation stagger-5 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden"
                        >
                            {/* Decorative Elements */}
                            <div className="absolute inset-0">
                                <div className="absolute -right-4 -top-4 w-32 h-32 bg-red-100/50 rounded-full blur-xl"></div>
                                <div className="absolute -left-4 -bottom-4 w-32 h-32 bg-red-50/50 rounded-full blur-xl"></div>
                                <div className="absolute inset-0 bg-[linear-gradient(125deg,rgba(255,255,255,0.3),rgba(255,255,255,0.2))] backdrop-blur-sm"></div>
                            </div>

                            <div className="relative">
                                {!analysisPerformed && !loading && (
                                    <div className="space-y-8">
                                        {/* Header */}
                                        <div className="text-center">
                                            <h1 className="text-2xl font-bold text-gray-900">
                                                {t("yourAnalysisBy")}{" "}
                                                <span className="bg-gradient-to-r from-red-500 to-red-600 bg-clip-text text-transparent">
                                                    {t("bloodFlowAI")}
                                                </span>
                                            </h1>
                                            <div className="mt-2 h-1 w-16 bg-gradient-to-r from-red-500 to-red-600 mx-auto rounded-full"></div>
                                        </div>

                                        {/* Steps Timeline */}
                                        <div className="max-w-3xl mx-auto">
                                            <div className="grid grid-cols-4 gap-6">
                                                {[
                                                    { icon: FileText, label: "Upload", delay: "0" },
                                                    { icon: Search, label: "Process", delay: "150" },
                                                    { icon: Brain, label: "Analyze", delay: "300" },
                                                    { icon: ChartBar, label: "Results", delay: "450" }
                                                ].map((step, index) => (
                                                    <div 
                                                        key={index} 
                                                        className="relative flex flex-col items-center"
                                                        style={{ animationDelay: `${step.delay}ms` }}
                                                    >
                                                        <div className="relative mb-4 group transition-all duration-300 hover:scale-105">
                                                            <div className="absolute inset-0 bg-red-100 rounded-xl blur-lg animate-pulse"></div>
                                                            <div className="relative p-4 bg-white rounded-xl shadow-sm border border-red-100 group-hover:border-red-200 transition-all duration-300">
                                                                <step.icon className="w-7 h-7 text-red-500" />
                                                            </div>
                                                        </div>
                                                        <span className="text-sm font-medium text-gray-700">{step.label}</span>
                                                        {index < 3 && (
                                                            <div className="hidden md:block absolute left-1/2 top-1/2 w-full h-[2px] bg-gradient-to-r from-red-200 to-red-100 transform translate-y-[-50%]"></div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Info Card */}
                                        <div className="max-w-lg mx-auto">
                                            <div className="bg-gradient-to-br from-white via-red-50/5 to-white rounded-2xl p-6 border border-red-100/20 backdrop-blur-sm">
                                                <p className="text-gray-900 font-semibold mb-2 text-center">
                                                    {t("analyseFileResultsHere")}
                                                </p>
                                                <p className="text-sm text-gray-600 text-center">
                                                    {t("startByUp")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {analysisPerformed && (
                                    <div className="bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden rounded-xl border border-gray-100 hover:border-red-100">
                                        {/* Analysis Header */}
                                        <div className="flex flex-col items-center space-y-4 mb-12">
                                            {/* Title and Subtitle */}
                                            <div className="text-center space-y-2">
                                                <h1 className="text-2xl font-bold text-gray-900">
                                                    {t("yourAnalysisBy")}{" "}
                                                    <span className="relative">
                                                        <span className="bg-gradient-to-r from-red-500 to-red-600 bg-clip-text text-transparent">
                                                            {t("bloodFlowAI")}
                                                        </span>
                                                        <div className="absolute -bottom-1 left-0 w-full h-[2px] bg-gradient-to-r from-red-500/50 to-red-600/50 blur-sm"></div>
                                                    </span>
                                                </h1>
                                            </div>

                                            {/* Decorative Line */}
                                            <div className="relative w-24 h-1 mt-4">
                                                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 blur-sm"></div>
                                                <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-full"></div>
                                            </div>
                                        </div>

                                        {/* Tabs Navigation */}
                                        <div className="flex justify-center gap-8">
                                            <button
                                                onClick={() => handleTabChange("fullAnalysis")}
                                                className={`pb-4 px-4 relative transition-colors duration-200 ${
                                                    activeTab === "fullAnalysis"
                                                        ? "text-red-500 font-semibold"
                                                        : "text-gray-600 hover:text-gray-900"
                                                }`}
                                            >
                                                Full Analysis
                                                {activeTab === "fullAnalysis" && (
                                                    <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-red-500 to-red-600"></div>
                                                )}
                                            </button>

                                            <button
                                                onClick={() => handleTabChange("smartReport")}
                                                className={`pb-4 px-4 relative transition-colors duration-200 ${
                                                    activeTab === "smartReport"
                                                        ? "text-red-500 font-semibold"
                                                        : "text-gray-600 hover:text-gray-900"
                                                }`}
                                            >
                                                Smart Report
                                                {activeTab === "smartReport" && (
                                                    <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-red-500 to-red-600"></div>
                                                )}
                                            </button>

                                            {/* Commented out tabs with updated styling */}
                                            {/*
                                            <button
                                                onClick={() => handleTabChange("screeningAnalysis")}
                                                className={`pb-4 px-4 relative transition-colors duration-200 ${
                                                    activeTab === "screeningAnalysis"
                                                        ? "text-red-500 font-semibold"
                                                        : "text-gray-600 hover:text-gray-900"
                                                }`}
                                            >
                                                Screening Analysis
                                                {activeTab === "screeningAnalysis" && (
                                                    <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-red-500 to-red-600"></div>
                                                )}
                                            </button>

                                            <button
                                                onClick={() => handleTabChange("spatialView")}
                                                className={`pb-4 px-4 relative transition-colors duration-200 ${
                                                    activeTab === "spatialView"
                                                        ? "text-red-500 font-semibold"
                                                        : "text-gray-600 hover:text-gray-900"
                                                }`}
                                            >
                                                Spatial View
                                                {activeTab === "spatialView" && (
                                                    <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-red-500 to-red-600"></div>
                                                )}
                                            </button>
                                            */}
                                        </div>

                                        {/* Tab Contents */}
                                        <div className="mt-8">
                                            {/* Full Analysis Tab */}
                                            {activeTab === "fullAnalysis" && (
                                                <div className="space-y-6">
                                                    {loading ? (
                                                        <div className="flex items-center justify-center p-12">
                                                            <Spinner />
                                                        </div>
                                                    ) : (
                                                        <div className="space-y-6">
                                                            {(() => {
                                                                const { score, sections } = parseAnalysisText(displayedText);
                                                                return (
                                                                    <>
                                                                    <HealthScoreSection 
                                                                            score={score}
                                                                            fileScores={fileScores}
                                                                            selectedFileData={selectedFileData}
                                                                        />
                                                                        {sections.map((section) => (
                                                                            <AnalysisSection
                                                                                key={section.id}
                                                                                title={section.title}
                                                                                content={section.content}
                                                                                sectionNumber={section.id}
                                                                                isOpen={openSections[section.id] || false}
                                                                                onToggle={() => {
                                                                                    setOpenSections(prev => ({
                                                                                        ...prev,
                                                                                        [section.id]: !prev[section.id]
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        ))}
                                                                    </>
                                                                );
                                                            })()}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {/* Smart Report Tab */}
                                            {activeTab === "smartReport" && (
                                                <div className="space-y-8">
                                                    {smartReportLoading ? (
                                                        <div className="flex items-center justify-center py-12">
                                                            <Spinner />
                                                        </div>
                                                    ) : (
                                                        <div className="space-y-8">
                                                            {/* Stats Overview */}
                                                            {smartReport && smartReport.parameters?.length > 0 && (
                                                                <div className="grid grid-cols-3 gap-4 mb-8">
                                                                    <div className="bg-gradient-to-br from-green-50 to-white p-4 rounded-xl border border-green-100">
                                                                        <div className="flex items-center gap-2 mb-1">
                                                                            <div className="w-2 h-2 rounded-full bg-green-500"></div>
                                                                            <span className="text-sm font-medium text-gray-600">Normal Range</span>
                                                                        </div>
                                                                        <p className="text-2xl font-bold text-gray-900">
                                                                            {smartReport.parameters.filter(p => p.status === "normal").length}
                                                                        </p>
                                                                    </div>
                                                                    <div className="bg-gradient-to-br from-red-50 to-white p-4 rounded-xl border border-red-100">
                                                                        <div className="flex items-center gap-2 mb-1">
                                                                            <div className="w-2 h-2 rounded-full bg-red-500"></div>
                                                                            <span className="text-sm font-medium text-gray-600">Out of Range</span>
                                                                        </div>
                                                                        <p className="text-2xl font-bold text-gray-900">
                                                                            {smartReport.parameters.filter(p => p.status === "high" || p.status === "low").length}
                                                                        </p>
                                                                    </div>
                                                                    <div className="bg-gradient-to-br from-blue-50 to-white p-4 rounded-xl border border-blue-100">
                                                                        <div className="flex items-center gap-2 mb-1">
                                                                            <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                                                                            <span className="text-sm font-medium text-gray-600">Not Determined</span>
                                                                        </div>
                                                                        <p className="text-2xl font-bold text-gray-900">
                                                                            {smartReport.parameters.filter(p => p.status === "ND").length}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {/* Parameters Grid */}
                                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                                {smartReport && smartReport.parameters?.length > 0 ? (
                                                                    smartReport.parameters.map((param, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="group relative bg-white p-6 rounded-xl border border-gray-100 shadow-sm 
                                                                                    hover:shadow-md transition-all duration-300 overflow-hidden"
                                                                        >
                                                                            {/* Background Pattern */}
                                                                            <div className="absolute inset-0 opacity-5 bg-[radial-gradient(#666_1px,transparent_1px)] [background-size:16px_16px]"></div>
                                                                            
                                                                            {/* Status Indicator */}
                                                                            <div className={`absolute top-0 right-0 w-16 h-16 ${
                                                                                param.status === "high" || param.status === "low"
                                                                                    ? "bg-red-100"
                                                                                    : param.status === "ND"
                                                                                    ? "bg-blue-100"
                                                                                    : "bg-green-100"
                                                                            } rounded-bl-[100%] transition-colors duration-300`}></div>
                                                                            
                                                                            <div className="relative">
                                                                                {/* Header */}
                                                                                <div className="flex items-center gap-3 mb-4">
                                                                                    <div className={`w-3 h-3 rounded-full ${
                                                                                        param.status === "high" || param.status === "low"
                                                                                            ? "bg-red-500"
                                                                                            : param.status === "ND"
                                                                                            ? "bg-blue-500"
                                                                                            : "bg-green-500"
                                                                                    }`}></div>
                                                                                    <h3 className="text-lg font-semibold text-gray-900">
                                                                                        {param.name}
                                                                                    </h3>
                                                                                </div>
                                                                                
                                                                                {/* Values */}
                                                                                <div className="grid grid-cols-2 gap-4 mb-4">
                                                                                    <div className="bg-gray-50 p-3 rounded-lg">
                                                                                        <p className="text-xs text-gray-500 mb-1">Value</p>
                                                                                        <p className="text-sm font-medium text-gray-900">{param.value}</p>
                                                                                    </div>
                                                                                    <div className="bg-gray-50 p-3 rounded-lg">
                                                                                        <p className="text-xs text-gray-500 mb-1">Range</p>
                                                                                        <p className="text-sm font-medium text-gray-900">{param.range}</p>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                {/* Insight */}
                                                                                <div className="relative">
                                                                                    <div className="absolute left-0 top-0 w-1 h-full bg-gradient-to-b from-gray-200 to-transparent"></div>
                                                                                    <p className="pl-4 text-sm text-gray-600 leading-relaxed">
                                                                                        {param.insight}
                                                                                    </p>
                                                                                </div>

                                                                                {/* Status Label */}
                                                                                <div className="absolute top-4 right-4">
                                                                                    <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                                                                                        param.status === "high" || param.status === "low"
                                                                                            ? "bg-red-50 text-red-600"
                                                                                            : param.status === "ND"
                                                                                            ? "bg-blue-50 text-blue-600"
                                                                                            : "bg-green-50 text-green-600"
                                                                                    }`}>
                                                                                        {param.status.toUpperCase()}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div className="col-span-full flex flex-col items-center gap-6 py-12">
                                                                        <div className="relative">
                                                                            <div className="absolute inset-0 bg-red-100 rounded-full blur-xl animate-pulse"></div>
                                                                            <div className="relative p-4 bg-white rounded-xl shadow-sm border border-red-100">
                                                                                <Activity className="w-8 h-8 text-red-500" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <p className="text-lg font-semibold text-gray-900 mb-2">No Smart Report Available</p>
                                                                            <p className="text-sm text-gray-500">Generate a smart report to see detailed analysis of your biomarkers.</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {/* Commented out tabs content with updated styling */}
                                            {/*
                                            {activeTab === "screeningAnalysis" && (
                                                <div className="space-y-6">
                                                    {analysisScreeningLoading ? (
                                                        <div className="flex items-center justify-center p-12">
                                                            <img src={BloodFlowLogoNL} alt="Logo" className="w-44 animate-spin" />
                                                        </div>
                                                    ) : screeningAnalysis ? (
                                                        <div className="prose max-w-none">
                                                            <ReactMarkdown
                                                                children={screeningAnalysis}
                                                                remarkPlugins={[remarkGfm]}
                                                                components={{
                                                                    strong: ({ children }) => (
                                                                        <strong className="text-red-500 font-semibold">{children}</strong>
                                                                    ),
                                                                    p: ({ children }) => (
                                                                        <p className="text-gray-700 leading-relaxed mb-4">{children}</p>
                                                                    ),
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <p className="text-center text-gray-500 py-12">
                                                            No screening analysis is available at the moment.
                                                        </p>
                                                    )}
                                                </div>
                                            )}

                                            {activeTab === "spatialView" && (
                                                <div className="space-y-6">
                                                    {smartReportLoading ? (
                                                        <div className="flex items-center justify-center p-12">
                                                            <img src={BloodFlowLogoNL} alt="Logo" className="w-44 animate-spin" />
                                                        </div>
                                                    ) : smartReport ? (
                                                        <div className="bg-white rounded-xl p-6 shadow-sm">
                                                            <SpatialVisualization smartReport={smartReport} />
                                                        </div>
                                                    ) : (
                                                        <p className="text-center text-gray-500 py-12">
                                                            No data available for spatial visualization.
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Footer */}
                        <footer className="relative stagger-animation stagger-5 bg-white p-8 shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden">
                        {/* Decorative elements */}
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-[500px] h-[200px]">
                            <div className="absolute inset-0 bg-gradient-to-b from-red-50/50 to-transparent blur-3xl"></div>
                            </div>
                        </div>

                        {/* Logo and content */}
                        <div className="relative">
                            {/* Logo */}
                            <div className="flex justify-center">
                            <div className="relative group">
                                <div className="absolute inset-0 bg-red-100 rounded-full blur-xl opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
                                <img 
                                className="w-32 md:w-40 relative transform hover:scale-105 transition-transform duration-300" 
                                src={BloodFlowLogoNL} 
                                alt="BloodFlow Logo" 
                                />
                            </div>
                            </div>

                            {/* Copyright and additional info */}
                            <div className="max-w-7xl mx-auto px-6 md:px-12 lg:px-24">
                            <div className="flex flex-col items-center gap-4">
                                {/* Copyright text */}
                                <p className="text-center text-sm text-gray-600 font-medium">
                                © {new Date().getFullYear()} BloodFlow. {t('allRightsReserved')}
                                </p>

                                {/* Divider */}
                                <div className="h-0.5 w-24 bg-gradient-to-r from-transparent via-red-200 to-transparent"></div>

                                {/* Additional text */}
                                <p className="text-center text-xs text-gray-500">
                                {t('poweringHealthcare')}
                                </p>
                            </div>
                            </div>
                        </div>
                        </footer>
                    </div>
                </main>
    
                {/* Mobile Menu Overlay */}
                {nav && (
                    <div 
                        className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                        onClick={handleSideBar}
                    />
                )}
    
                {/* Modals */}
                <EditPersonalInfo 
                    show={showEditPopup} 
                    onClose={handleClosePopup} 
                    userInfo={userData} 
                    userId={user?.uid}
                />

                {selectedFilesForUpload && !uploadSuccess && showNewFileModal && (
                    <NewFilePopup 
                        show={showNewFileModal} 
                        onClose={handleCancelFileUpload} 
                        onConfirm={handleUpload} 
                        fileName={selectedFilesForUpload.name}
                    />
                )}
    
                <UpgradeModal 
                    isOpen={showUpgradeModal}
                    onClose={() => setShowUpgradeModal(false)}
                    onUpgrade={() => {
                        setShowUpgradeModal(false);
                        navigate('/dashboard/subscription');
                    }}
                    t={t}
                />
            </div>
        )
    );
}

export default Dashboard;