import React from 'react';
import CubeSpinner from './CubeSpinner';

const Spinner = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 via-white to-red-50 flex items-center justify-center">
      <CubeSpinner />
    </div>
  );
};

export default Spinner;