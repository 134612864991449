import React, { useState, useEffect, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, orderBy, limit, getDocs, Timestamp } from "firebase/firestore";
import { format } from 'date-fns'; 

const ApiDashboard = () => {
  const [apiData, setApiData] = useState([]);
  const [timeRange, setTimeRange] = useState('24h');
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('requests');
  const [user, setUser] = useState(null);

  // Get current user
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    
    return () => unsubscribe();
  }, []);
  
  const formatDataByTimeIntervals = (logs, timeRange) => {
    const intervals = {};
    
    logs.forEach(log => {
      const date = log.timestamp.toDate();
      let intervalKey;
      let displayKey;
      
      if (timeRange === '24h') {
        // Create a sort key that includes date and time
        intervalKey = format(date, 'yyyy-MM-dd-HH:00');
        // Create a display key that shows only the time
        displayKey = format(date, 'HH:00');
      } else if (timeRange === '7d') {
        intervalKey = format(date, 'MM/dd');
        displayKey = intervalKey;
      } else {
        intervalKey = format(date, 'MM/dd');
        displayKey = intervalKey;
      }
      
      if (!intervals[intervalKey]) {
        intervals[intervalKey] = {
          sortKey: intervalKey,
          name: displayKey,
          successful: 0,
          failed: 0,
          total: 0,
          responseTime: 0,
          count: 0
        };
      }
      
      intervals[intervalKey].total++;
      if (log.successful) intervals[intervalKey].successful++;
      else intervals[intervalKey].failed++;
      intervals[intervalKey].responseTime += log.responseTime;
      intervals[intervalKey].count++;
    });

    return Object.values(intervals)
      .map(interval => ({
        ...interval,
        avgResponseTime: interval.responseTime / interval.count
      }))
      .sort((a, b) => {
        // Sort based on the full timestamp for 24h view
        if (timeRange === '24h') {
          return a.sortKey.localeCompare(b.sortKey);
        }
        return a.name.localeCompare(b.name);
      });
  };

  // Fetch data from Firestore
  const fetchData = useCallback(async () => {
    if (!user) return;
    
    setIsLoading(true);
    
    try {
      const db = getFirestore();
      
      // Calculate time range
      const now = new Date();
      let startTime = new Date();
      
      switch(timeRange) {
        case '24h':
          startTime.setHours(now.getHours() - 24);
          break;
        case '7d':
          startTime.setDate(now.getDate() - 7);
          break;
        case '30d':
          startTime.setDate(now.getDate() - 30);
          break;
        default:
          startTime.setHours(now.getHours() - 24);
      }
      
      // Convert to Firestore Timestamp
      const firestoreStartTime = Timestamp.fromDate(startTime);
      
      const logsRef = collection(db, 'api_logs');
      const q = query(
        logsRef,
        where('timestamp', '>=', firestoreStartTime),
        orderBy('timestamp', 'desc'),
        limit(1000)
      );
      
      const querySnapshot = await getDocs(q); // Await the data fetching
      const logs = [];
      
      // Process logs
      querySnapshot.forEach((doc) => {
        logs.push(doc.data());
      });
      
      // Extract unique endpoints
      const uniqueEndpoints = [...new Set(logs.map(log => log.endpoint))];
      
      // Format data for charts
      const endpointStats = {};
      uniqueEndpoints.forEach(endpoint => {
        const endpointLogs = logs.filter(log => log.endpoint === endpoint);
        endpointStats[endpoint] = formatDataByTimeIntervals(endpointLogs, timeRange);
      });
      
      setApiData(endpointStats);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching API data:", error);
      setIsLoading(false);
    }
  }, [timeRange, user]);
  
  // Fetch data on component mount and when time range or user changes
  useEffect(() => {
    if (user) {
      fetchData();
      
      // Set up auto-refresh every 5 minutes
      const intervalId = setInterval(fetchData, 300000);
      
      return () => clearInterval(intervalId);
    }
  }, [timeRange, user, fetchData]);
  
  if (!user) {
    return null;
  }
  
  return (
    <div className="min-h-screen bg-zinc-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-zinc-900 rounded-xl p-6">
          {/* Header */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
            <h1 className="text-2xl font-medium text-white">API Monitoring</h1>
            
            <div className="flex items-center bg-zinc-800 rounded-lg"> 
              <select 
                className="bg-transparent border-none focus:ring-0 text-zinc-300 px-3 py-2 rounded-lg cursor-pointer hover:bg-zinc-700/50 transition-colors text-sm"
                value={timeRange} 
                onChange={(e) => setTimeRange(e.target.value)}
              >
                <option value="24h">Last 24 Hours</option>
                <option value="7d">Last 7 Days</option>
                <option value="30d">Last 30 Days</option>
              </select>
            </div>
          </div>
          
          {/* Tabs */}
          <div className="mb-8">
            <div className="flex space-x-1 rounded-lg bg-zinc-600/50 p-0.5">
              {['requests', 'success-failure', 'response-time'].map((tab) => (
                <button
                  key={tab}
                  className={`flex-1 px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                    activeTab === tab
                      ? 'bg-zinc-700 text-white'
                      : 'text-zinc-400 hover:text-white'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'requests' && 'Requests'}
                  {tab === 'success-failure' && 'Success/Failure'}
                  {tab === 'response-time' && 'Response Time'}
                </button>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="space-y-8">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-zinc-600 border-t-red-500"></div>
              </div>
            ) : (
              Object.entries(apiData).map(([endpoint, data]) => (
                <div key={endpoint} className="bg-zinc-700/50 rounded-lg p-6">
                  <h3 className="text-sm font-medium text-zinc-300 mb-6">{endpoint}</h3>
                  <div className="h-[300px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={data}
                        margin={{ top: 0, right: 0, left: -15, bottom: 0 }}
                        barSize={20}
                      >
                        <XAxis 
                          dataKey="name" 
                          tick={{ fontSize: 11, fill: '#71717a' }}
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis 
                          tick={{ fontSize: 11, fill: '#71717a' }}
                          axisLine={false}
                          tickLine={false}
                        />
                        <Tooltip 
                          cursor={false}
                          contentStyle={{ 
                            backgroundColor: 'rgba(24, 24, 27, 0.98)',
                            border: 'none',
                            borderRadius: '6px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                            fontSize: '12px',
                            padding: '8px 12px'
                          }}
                          itemStyle={{ color: '#e4e4e7' }}
                          labelStyle={{ color: '#a1a1aa', marginBottom: '4px' }}
                        />
                        <Legend 
                          wrapperStyle={{ fontSize: '11px', color: '#71717a' }}
                          iconType="circle"
                          iconSize={8}
                        />
                        {activeTab === 'requests' && (
                          <Bar dataKey="total" fill="#ef4444" fillOpacity={0.9} radius={[2, 2, 0, 0]} />
                        )}
                        {activeTab === 'success-failure' && (
                          <>
                            <Bar dataKey="successful" fill="#22c55e" fillOpacity={0.9} radius={[2, 2, 0, 0]} />
                            <Bar dataKey="failed" fill="#ef4444" fillOpacity={0.9} radius={[2, 2, 0, 0]} />
                          </>
                        )}
                        {activeTab === 'response-time' && (
                          <Bar dataKey="avgResponseTime" fill="#f97316" fillOpacity={0.9} radius={[2, 2, 0, 0]} />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDashboard;
