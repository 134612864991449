import React, { useMemo } from 'react';
import { TrendingUp, TrendingDown, MinusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const HealthScoreSection = ({ score, fileScores, selectedFileData }) => {

  const { t } = useTranslation();

  const getScoreStyles = (score) => {
    if (score <= 4.5) {
      return {
        gradient: 'from-red-500 to-rose-600',
        glow: 'from-red-500/20 to-rose-500/20',
        dots: ['bg-red-400', 'bg-rose-400']
      };
    } else if (score <= 6.5) {
      return {
        gradient: 'from-amber-500 to-yellow-600',
        glow: 'from-amber-500/20 to-yellow-500/20',
        dots: ['bg-amber-400', 'bg-yellow-400']
      };
    } else {
      return {
        gradient: 'from-emerald-500 to-green-600',
        glow: 'from-emerald-500/20 to-green-500/20',
        dots: ['bg-emerald-400', 'bg-green-400']
      };
    }
  };

  // Calculate previous score and change using fileScores array and selected file
  const { previousScore, changePercentage, trend, changeTrend } = useMemo(() => {
    if (!fileScores || fileScores.length < 2) {
      return { previousScore: null, changePercentage: 0, trend: 'stable', changeTrend: '-' };
    }
  
    const selectedIndex = fileScores.findIndex(
      (file) => file.name === selectedFileData?.name
    );
  
    if (selectedIndex === -1) {
      return { previousScore: null, changePercentage: 0, trend: 'stable', changeTrend: '-' };
    }
  
    const currentScore = fileScores[selectedIndex].score;
    const prevScore = selectedIndex > 0 ? fileScores[selectedIndex - 1].score : null;
  
    if (!prevScore) {
      return { previousScore: null, changePercentage: 0, trend: 'stable', changeTrend: '-' };
    }
  
    const change = ((currentScore - prevScore) / prevScore) * 100;
    
    // Status trend based on trendText
    let trendDirection = 'stable';
    if (score.trendText?.toLowerCase().includes('improving')) {
      trendDirection = 'improving';
    } else if (score.trendText?.toLowerCase().includes('declining')) {
      trendDirection = 'declining';
    }
  
    // Separate trend for percentage change
    const changeTrendDirection = change > 0 ? '↑' : change < 0 ? '↓' : '-';
  
    return {
      previousScore: prevScore,
      changePercentage: change.toFixed(1),
      trend: trendDirection,
      changeTrend: changeTrendDirection
    };
  }, [fileScores, selectedFileData, score.trendText]);
  
  const getTrendInfo = (trendType) => {
    switch (trendType) {
      case 'improving':
      case '↑':
        return {
          icon: TrendingUp,
          colors: 'text-emerald-600 bg-emerald-50',
          ringColor: 'ring-emerald-500/20',
          indicator: 'bg-emerald-500',
          changeColor: 'text-emerald-600'
        };
      case 'declining':
      case '↓':
        return {
          icon: TrendingDown,
          colors: 'text-rose-600 bg-rose-50',
          ringColor: 'ring-rose-500/20',
          indicator: 'bg-rose-500',
          changeColor: 'text-rose-600'
        };
      default:
        return {
          icon: MinusCircle,
          colors: 'text-amber-600 bg-amber-50',
          ringColor: 'ring-amber-500/20',
          indicator: 'bg-amber-500',
          changeColor: 'text-gray-600'
        };
    }
  };
  
  const statusTrendInfo = getTrendInfo(trend);
  const changeTrendInfo = getTrendInfo(changeTrend);
  const StatusTrendIcon = statusTrendInfo.icon;
  const ChangeTrendIcon = changeTrendInfo.icon;

  return (
    <div className="relative overflow-hidden rounded-2xl bg-white border border-gray-100">
      {/* Modern gradient background */}
      <div className="absolute inset-0 bg-[radial-gradient(at_top_right,theme(colors.gray.50),transparent_50%)]" />
      
      <div className="relative p-8">
        <div className="flex flex-col md:flex-row items-center gap-8">
          {/* Score Section */}
          <div className="flex-shrink-0">
            <div className="relative">
              {/* Glowing background effect */}
              <div className="absolute inset-0 bg-gradient-to-br from-red-500/20 to-rose-500/20 blur-2xl" />
              
              <div className={`absolute inset-0 bg-gradient-to-br ${getScoreStyles(score.score).glow} blur-2xl`} />
    
              {/* Score display */}
              <div className="relative">
                <div className={`w-32 h-32 rounded-2xl bg-gradient-to-br ${getScoreStyles(score.score).gradient} flex items-center justify-center shadow-lg`}>
                  <span className="text-5xl font-bold text-white">{score.score}</span>
                </div>
                
                {/* Decorative elements */}
                <div className={`absolute -top-1 -right-1 w-4 h-4 rounded-full ${getScoreStyles(score.score).dots[0]} blur opacity-80`} />
                <div className={`absolute -bottom-1 -left-1 w-4 h-4 rounded-full ${getScoreStyles(score.score).dots[1]} blur opacity-80`} />
              </div>
            </div>
          </div>

          {/* Details Section */}
          <div className="flex-grow space-y-6">
            {/* Header and Date */}
            <div className='text-center md:text-left'>
              <h2 className="text-xl font-semibold text-gray-900">Analysis Score</h2>
              <div className="mt-1 flex items-center gap-2 justify-center sm:justify-start">
                <span className="text-sm text-gray-500">{t('analysisDate')}:</span>
                <span className="text-sm font-medium text-gray-700">{score.date}</span>
              </div>
            </div>

            {/* Metrics Grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {/* Trend Card */}
              <div className={`flex items-center gap-3 p-3 rounded-xl ${statusTrendInfo.colors} ring-1 ${statusTrendInfo.ringColor}`}>
                <div className="flex-shrink-0">
                    <StatusTrendIcon className="w-5 h-5" />
                </div>
                <div>
                    <div className="text-xs font-medium opacity-70">Trend</div>
                    <div className="text-sm font-semibold">{score.trendText}</div>
                </div>
              </div>

              {/* Previous Score Card */}
              <div className="group flex items-center gap-3 p-3 rounded-xl bg-gray-50 ring-1 ring-gray-100 hover:bg-gray-100/50 transition-all duration-300">
                <div className="relative">
                  <div className="absolute inset-0 bg-gray-400/20 rounded-full blur-sm"></div>
                  <div className="relative w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                    <span className="text-xs font-medium text-gray-600">
                      {previousScore ? Math.round(previousScore) : '-'}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">Previous</div>
                  <div className="flex items-baseline gap-1">
                    <span className="text-sm font-semibold text-gray-900">
                      {previousScore ? Math.round(previousScore) : '-'}
                    </span>
                    <span className="text-xs text-gray-500">pts</span>
                  </div>
                </div>
              </div>

              {/* Change Percentage Card */}
              <div className="group flex items-center gap-3 p-3 rounded-xl bg-gray-50 ring-1 ring-gray-100 hover:bg-gray-100/50 transition-all duration-300">
                <div className="relative">
                  <div className={`absolute inset-0 ${changeTrendInfo.indicator}/20 rounded-full blur-sm`}></div>
                  <div className="relative w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
                    <ChangeTrendIcon className={`w-4 h-4 ${changeTrendInfo.changeColor}`} />
                  </div>
                </div>
                <div>
                  <div className="text-xs text-gray-500">Change</div>
                  <div className="flex items-baseline gap-1">
                    <span className={`text-sm font-semibold ${changeTrendInfo.changeColor}`}>
                      {changePercentage > 0 ? `+${changePercentage}` : changePercentage}
                    </span>
                    <span className="text-xs text-gray-500">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthScoreSection;