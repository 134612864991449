import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './Components/HomePage/Navbar';
import Hero from './Components/HomePage/Hero';
import Services from './Components/HomePage/Services';
import FAQ from './Components/HomePage/FAQSection';
import Footer from './Components/HomePage/Footer';
import SignUpPage from './Components/SignUpPage/SignUpPage';
import LoginPage from './Components/LoginPage/LoginPage';
import Dashboard from './Components/Dashboard/Dashboard';
import FertilityCareDashboard from './Components/DashboardFertilityCare/Dashboard';
import ForgotPassword from './Components/LoginPage/ForgotPassword';
import FollowUpDashboard from './Components/DashboardFollowUp/Dashboard';
import usePageTracking from './PageTracking';
import SuccessPage from './pages/checkout/CheckoutSuccess';
import FailedPage from './pages/checkout/CheckoutFailed';
import ManageSubscriptionPage from './pages/subscriptions/ManageSubscriptionPage';
import MonitoringDashboard from './Components/MonitoringDashboard/MonitoringDashboard';
import MonitoringDashboardLogin from './Components/MonitoringDashboard/MonitoringDashboardLogin';
import ProtectedRoute from './Components/MonitoringDashboard/ProtectedRoute';
import { Navigate } from 'react-router-dom';

const HomePage = () => (
  <div>
    <Navbar />
    <Hero />
    <Services />
    <FAQ />
    <Footer />
  </div>
);

function AppRoutes() {
  usePageTracking();
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={<Dashboard />}/>
      <Route path="/fertility-care-dashboard" element={<FertilityCareDashboard />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/follow-up-form-fertilitycare" element={<FollowUpDashboard />} />
      <Route path="/checkout/success" element={<SuccessPage />} />
      <Route path="/checkout/failed" element={<FailedPage />} />
      <Route path="/dashboard/subscription" element={<ManageSubscriptionPage />} />
      <Route path="/admin/monitoring-dashboard" element={<MonitoringDashboardLogin />} />

      <Route 
          path="/admin/monitoring-dashboard/view" 
          element={
            <ProtectedRoute>
              <MonitoringDashboard />
            </ProtectedRoute>
          } 
        />
        
        {/* Catch-all redirect to login */}
        <Route path="/admin/*" element={<Navigate to="/admin/monitoring-dashboard" replace />} />
        

    </Routes>
  );
}

export default AppRoutes;
