import React, { useState } from 'react';
import BloodFlowLogo from "../../Assets/logoBloodFlowNav.png";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { setPersistence, browserLocalPersistence, signInWithCustomToken } from "firebase/auth";
import { getDoc, doc } from 'firebase/firestore';
import { db, auth } from "../../firebase";
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from "lucide-react";
import { motion } from 'framer-motion';

function LoginPage() {
  const [userEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  const loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);

      try {
        const response = await fetch(`${BASE_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: userEmail, password }),
        });

        if (!response.ok) {
          throw new Error(t('loginError'));
        }
        
        const data = await response.json();
        const customToken = data.token;

        await setPersistence(auth, rememberMe ? browserLocalPersistence : browserLocalPersistence);
        const userCredential = await signInWithCustomToken(auth, customToken);
        const user = userCredential.user;

        await user.reload();

        if(!user.emailVerified) {
          setLoading(false);
          await sendEmailVerification(user);
          return toast.error(t('registerToastVerifyEmailYet'));
        }

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();

        if (userData) {
          toast.success(`${t('bemVindo')} ${userData.username}!`);
    
          if (userData.role === 'fertilitycare') {
            navigate('/fertility-care-dashboard', { state: { user: userData } });
          } else {
            navigate('/dashboard', { state: { user: userData } });
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(t('loginError'));
      }
  };

  const backToHome = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 via-white to-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full space-y-8 bg-white/70 backdrop-blur-xl rounded-2xl shadow-xl p-8 border border-gray-100"
      >
        <div>
          <motion.img
            whileHover={{ scale: 1.05 }}
            onClick={backToHome}
            className="mx-auto h-16 w-auto cursor-pointer"
            src={BloodFlowLogo}
            alt="BloodFlow Logo"
          />
          <motion.h1 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="mt-6 text-center text-3xl font-extrabold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
          >
            {t('InicieSessãoNaConta')}
          </motion.h1>
        </div>
        
        <form onSubmit={loginUser} className="mt-8 space-y-6">
          <motion.div 
            className="space-y-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <div className="group">
              <input
                type="text"
                placeholder="Email"
                value={userEmail}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-400 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200 bg-white/50 backdrop-blur-sm group-hover:bg-white"
              />
            </div>
            
            <div className="group">
              <input
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none relative block w-full px-4 py-3 border border-gray-200 placeholder-gray-400 text-gray-900 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all duration-200 bg-white/50 backdrop-blur-sm group-hover:bg-white"
              />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="flex items-center justify-between"
          >
            <div className="flex items-center">
              <input
                id="remember-me"
                type="checkbox"
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-600">
                {t('rememberMe')}
              </label>
            </div>
            <RouterLink
              to="/signup"
              className="text-sm font-medium text-red-600 hover:text-red-500 transition-colors duration-200"
            >
              {t('CriarConta')}
            </RouterLink>
          </motion.div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white shadow-lg transition-all duration-300 bg-gradient-to-r from-red-600 to-red-500 hover:from-red-500 hover:to-red-600"
            disabled={loading}
          >
            <span className="absolute right-4 transition-transform duration-200 group-hover:translate-x-1">
              <ChevronRight className="h-5 w-5" />
            </span>
            {loading ? (
              <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
            ) : (
              t('login')
            )}
          </motion.button>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-center"
          >
            <RouterLink
              to="/forgotpassword"
              className="text-sm text-gray-500 hover:text-gray-700 transition-colors duration-200"
            >
              {t('forgotPassword')}
            </RouterLink>
          </motion.div>
        </form>
      </motion.div>
    </div>
  );
}

export default LoginPage;