import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';

const ExcelPreview = ({ fileUrl }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSheet, setActiveSheet] = useState('');
  const [fileName, setFileName] = useState('Patient_Excel.xlsx'); // Default name

  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        setLoading(true);

        const response = await fetch(fileUrl);
        const blob = await response.blob();

        // Extract file name from URL
        const urlWithoutParams = fileUrl.split("?")[0];
        const extractedFileName = decodeURIComponent(urlWithoutParams.split("/").pop()); 
        setFileName(extractedFileName);

        // Create a new workbook
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await blob.arrayBuffer());

        const sheetsData = {};
        workbook.worksheets.forEach(worksheet => {
          const sheetData = [];
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            sheetData[rowNumber - 1] = row.values.slice(1); // Remove the first undefined element
          });
          sheetsData[worksheet.name] = sheetData;
        });

        setData({
          sheetNames: workbook.worksheets.map(sheet => sheet.name),
          sheets: sheetsData
        });
        setActiveSheet(workbook.worksheets[0].name);

      } catch (err) {
        console.error('Error processing Excel file:', err);
        setError('Failed to parse Excel file');
      } finally {
        setLoading(false);
      }
    };

    if (fileUrl) fetchExcelData();
  }, [fileUrl]);

  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName.endsWith(".xlsx") ? fileName : `${fileName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Download failed:', err);
    }
  };

  if (loading) return <div className="h-60 w-full bg-gray-200 animate-pulse rounded-md" />;
  if (error) return (
    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700 rounded-md">
      <span className="font-semibold">Error:</span> {error}
    </div>
  );

  if (!data) return <div className="text-center p-6">No data available</div>;

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden border">
      {/* Title & Download Button */}
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="text-lg font-bold text-gray-800">Patient Excel View</h2>
        <button 
          onClick={handleDownload} 
          className="flex items-center gap-2 text-red-600 hover:text-red-800 transition"
        >
          <span className="text-sm font-medium">Download</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v12m0 0l-3-3m3 3l3-3m-6 6h6m-9 3h12" />
          </svg>
        </button>
      </div>

      {/* Sheet Tabs */}
      <div className="flex border-b bg-gray-100 px-1">
        {data.sheetNames.map(sheetName => (
            <button
            key={sheetName}
            onClick={() => setActiveSheet(sheetName)}
            className={`px-2 py-1 text-xs font-medium transition ${
                activeSheet === sheetName
                ? 'border-b-2 border-red-500 text-red-600'
                : 'text-gray-600 hover:text-red-600'
            }`}
            >
            {sheetName}
            </button>
        ))}
        </div>


      {/* Sheet Content */}
      <div className="overflow-x-auto p-4">
        <table className="w-full border border-gray-200 rounded-lg text-xs">
            <thead className="bg-gray-200 text-gray-700">
                {data?.sheets[activeSheet]?.[0] && (
                <tr>
                    {data.sheets[activeSheet][0].map((header, index) => (
                    <th key={index} className="px-2 py-1 text-left border">
                        {header}
                    </th>
                    ))}
                </tr>
                )}
            </thead>
            <tbody>
                {data?.sheets[activeSheet]?.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex} className="border-b hover:bg-gray-50 transition">
                    {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className="px-2 py-1 border text-gray-700">
                        {cell}
                    </td>
                    ))}
                </tr>
                ))}
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcelPreview;
