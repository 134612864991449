import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
  CheckCircle2, 
  XCircle, 
  Calendar, 
  AlertTriangle 
} from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Components/Spinner';

function ManageSubscriptionPage() {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          navigate('/login');
          return;
        }

        const response = await fetch(`${BASE_URL}/manage-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: user.uid })
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch subscription details');
        }

        const data = await response.json();
        setSubscriptionDetails(data);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [navigate, BASE_URL]);

  const handleCancelSubscription = async () => {
    setActionLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      const response = await fetch(`${BASE_URL}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.uid })
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      const data = await response.json();
      
      // Refresh subscription details
      const refreshResponse = await fetch(`${BASE_URL}/manage-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.uid })
      });

      const refreshData = await refreshResponse.json();
      setSubscriptionDetails(refreshData);
      
      toast.success(data.message);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      toast.error('Failed to cancel subscription');
    } finally {
      setActionLoading(false);
    }
  };

  const handleReactivateSubscription = async () => {
    setActionLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      const response = await fetch(`${BASE_URL}/reactivate-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.uid })
      });

      if (!response.ok) {
        throw new Error('Failed to reactivate subscription');
      }

      const data = await response.json();
      
      // Refresh subscription details
      const refreshResponse = await fetch(`${BASE_URL}/manage-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.uid })
      });

      const refreshData = await refreshResponse.json();
      setSubscriptionDetails(refreshData);
      
      toast.success(data.message);
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      toast.error('Failed to reactivate subscription');
    } finally {
      setActionLoading(false);
    }
  };

  const handleUpgradeSubscription = () => {
    navigate('/#pricing');
};

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center">
        <div className="bg-white rounded-2xl shadow-xl p-8 text-center max-w-md">
          <XCircle className="mx-auto h-16 w-16 text-red-500 mb-4" />
          <h2 className="text-2xl font-bold text-red-700 mb-2">Error</h2>
          <p className="text-red-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-red-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white rounded-2xl shadow-xl p-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-red-600 to-red-500 bg-clip-text text-transparent">
            {t('subDetails')}
          </h2>
          <p className="text-gray-600 mt-2">{t('manageSub')}</p>
        </div>

        <div className="space-y-6">
          {/* Subscription Status */}
          <div className="bg-gradient-to-br from-white via-red-50 to-white rounded-xl p-4 shadow-sm">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                {subscriptionDetails.status === 'pro' ? (
                  <CheckCircle2 className="text-green-500" />
                ) : subscriptionDetails.status === 'pending_cancellation' ? (
                  <AlertTriangle className="text-yellow-500" />
                ) : (
                  <XCircle className="text-red-500" />
                )}
                <span className="font-medium text-gray-800">
                  {subscriptionDetails.status === 'pro' 
                    ? t('activatePro')
                    : subscriptionDetails.status === 'pending_cancellation'
                    ? t('cancelPending')
                    : t('freePlan')}
                </span>
              </div>
            </div>
          </div>

          {/* Plan Details */}
          {(subscriptionDetails.status === 'pro' || 
            subscriptionDetails.status === 'pending_cancellation') && (
            <div className="bg-blue-50 rounded-xl p-4 shadow-sm">
              <div className="flex items-center space-x-3">
                <Calendar className="text-blue-500" />
                <span className="font-medium text-gray-800">
                  {subscriptionDetails.status === 'pending_cancellation'
                    ? t('cancelsOn')
                    : t('nextBilling')}
                  {subscriptionDetails.nextBillingDate}
                </span>
              </div>
              <p className="text-sm text-blue-700 mt-2">
                {t('currentPlan')} {subscriptionDetails.currentPlan}
              </p>
            </div>
          )}

          {/* Action Buttons */}
          <div className="space-y-4">
          {subscriptionDetails.status === 'free' && (
                <button 
                    onClick={handleUpgradeSubscription}
                    disabled={actionLoading}
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 disabled:opacity-50"
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                    <span className="relative z-10 flex items-center justify-center">
                    {actionLoading ? (
                        <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    ) : (
                        <>
                        {t('upgradeToProNow')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                        </>
                    )}
                    </span>
                </button>
                )}

                {subscriptionDetails.status === 'pro' && (
                <button 
                    onClick={handleCancelSubscription}
                    disabled={actionLoading}
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 disabled:opacity-50"
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                    <span className="relative z-10 flex items-center justify-center">
                    {actionLoading ? (
                        <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    ) : (
                        <>
                        {t('cancelSub')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                        </>
                    )}
                    </span>
                </button>
                )}

                {subscriptionDetails.status === 'pending_cancellation' && (
                <button 
                    onClick={handleReactivateSubscription}
                    disabled={actionLoading}
                    className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5 disabled:opacity-50"
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-green-500 to-green-600 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                    <span className="relative z-10 flex items-center justify-center">
                    {actionLoading ? (
                        <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    ) : (
                        <>
                        {t('reactivateSub')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                        </>
                    )}
                    </span>
                </button>
                )}

                <RouterLink
                    to="/dashboard"
                    className="group relative w-full inline-flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-xl text-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-0.5"
                    >
                    <div className="absolute inset-0 bg-gradient-to-r from-red-500 to-red-800 rounded-xl transition-transform duration-300 group-hover:scale-105" />
                    <span className="relative z-10 flex items-center justify-center">
                        {t('backDashboard')}
                        <ChevronRight className="ml-2 size-4 transition-transform group-hover:translate-x-1" />
                    </span>
                </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSubscriptionPage;