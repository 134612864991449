import React from "react";
import BloodFlowLogo from "../../Assets/BloodflowLogo.png";
import { useTranslation } from 'react-i18next';
import { ChevronRight, Mail, MapPin, Globe2 } from 'lucide-react';
import { motion } from 'framer-motion';

const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <footer className="bg-gradient-to-b from-black to-gray-900 relative">
            {/* Background Effects */}
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:14px_24px]" />
                <div className="absolute top-0 left-1/4 w-96 h-96 bg-red-500/10 rounded-full filter blur-3xl animate-pulse opacity-25" />
                <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse delay-1000 opacity-25" />
            </div>

            {/* Main CTA Section */}
            <div className="relative z-10 max-w-7xl mx-auto px-6 pt-24 pb-32">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="bg-gradient-to-br from-red-500/10 to-gray-900 rounded-2xl p-8 lg:p-12 border border-red-500/30 text-center relative overflow-hidden"
                >
                    <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:14px_24px]" />
                    <div className="relative">
                        <h3 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-20 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                            {t('ReadyToTransform')}
                        </h3>

                        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                            <a 
                                href="https://calendly.com/tiago-costa-bloodflow"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group bg-white w-[250px] px-6 py-3 rounded-xl text-red-600 font-medium hover:bg-gray-50 transition-all duration-300 flex items-center justify-center"
                            >
                                {t('scheduleCall')}
                                <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                            </a>
                        </div>
                    </div>
                </motion.div>
            </div>

            {/* Simplified Footer Content */}
            <div id="contact" className="relative border-t border-gray-800">
                <div className="max-w-7xl mx-auto px-6 py-16">
                    <div className="flex flex-col md:flex-row items-start justify-between gap-12">
                        {/* Logo and Company Info */}
                        <div className="max-w-sm">
                            <img src={BloodFlowLogo} alt="BloodFlow" className="h-24 mb-6" />
                            <p className="text-gray-400 text-sm mb-6">
                                {t('companyDesc')}
                            </p>
                            <div className="space-y-3">
                                <a href={`mailto:${t('footerEmail')}`} className="flex items-center text-gray-400 hover:text-red-400 transition-colors">
                                    <Mail className="w-4 h-4 mr-2" />
                                    <span className="text-sm">{t('footerEmail')}</span>
                                </a>
                                <div className="flex items-start text-gray-400">
                                    <MapPin className="w-4 h-4 mr-2 mt-1" />
                                    <div className="text-sm">
                                        <p>{t('footerAddress')}</p>
                                        <p>{t('footerAddress2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Language Selector */}
                        <div className="flex flex-col items-end">
                            <div className="flex items-center gap-2 mb-4">
                                <Globe2 className="w-4 h-4 text-gray-400" />
                                <select 
                                    value={i18n.language}
                                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                                    className="bg-transparent text-gray-400 text-sm border-none focus:ring-0 cursor-pointer outline-none"
                                >
                                    <option value="en" className="bg-gray-900">English</option>
                                    <option value="pt" className="bg-gray-900">Português</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* Copyright */}
                    <div className="mt-16 pt-8 border-t border-gray-800">
                        <p className="text-sm text-gray-500 text-center">
                            © {new Date().getFullYear()} BloodFlow. {t('allRightsReserved')}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;