import React from 'react';
import { motion } from 'framer-motion';
import { Database, Brain, FileText, Activity, CheckCircle2, Sparkles } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const WorkflowVisualization = () => {
  const { t } = useTranslation();

  // Enhanced Flowing Particles with glowing effects
  const FlowingParticles = ({ direction = 'right' }) => (
    <div className="absolute top-1/2 -translate-y-1/2 w-full z-0">
      {[...Array(6)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute"
          animate={{
            x: direction === 'right' ? [-20, 320] : [320, -20],
            y: Math.sin(i) * 10,
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 3,
            delay: i * 0.3,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <div className="h-3 w-3 rounded-full bg-red-400 blur-sm" />
          <div className="absolute top-0 left-0 h-3 w-3 rounded-full bg-red-500 animate-ping" />
        </motion.div>
      ))}
    </div>
  );

  return (
    <div className="bg-white py-32 relative overflow-hidden">
      {/* Background effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:14px_24px]" />
        <motion.div
          animate={{
            opacity: [0.5, 0.8, 0.5],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-gradient-to-br from-red-500/5 to-blue-500/5 rounded-full blur-3xl"
        />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Section Title */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-20"
        >
          <motion.div
            className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-red-50 border border-red-100 mb-4"
          >
            <Sparkles className="w-4 h-4 text-red-500" />
            <span className="text-sm font-medium text-red-600">{t('workflow.title')}</span>
          </motion.div>
        </motion.div>

        {/* Responsive Container */}
        <div className="relative">
          {/* Mobile Workflow View */}
          <div className="lg:hidden space-y-8">
            {[
              {
                title: t('workflow.steps.lims.title'),
                icon: Database,
                color: "blue",
                items: [t('workflow.steps.lims.items.raw'), t('workflow.steps.lims.items.unstructured')]
              },
              {
                title: t('workflow.steps.ai.title'),
                icon: Brain,
                color: "red",
                items: [
                  t('workflow.steps.ai.items.normalization'),
                  t('workflow.steps.ai.items.pattern'),
                  t('workflow.steps.ai.items.risk'),
                  t('workflow.steps.ai.items.report')
                ]
              },
              {
                title: t('workflow.steps.ehr.title'),
                icon: Database,
                color: "green",
                items: [t('workflow.steps.ehr.items.structured'), t('workflow.steps.ehr.items.automated')]
              }
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="relative"
              >
                <div className="bg-white rounded-2xl p-6 shadow-xl">
                  <div className={`absolute -top-3 left-4 px-3 py-1 bg-${step.color}-500 text-white text-xs font-semibold rounded-full`}>
                    Step {index + 1}
                  </div>
                  <div className={`relative bg-${step.color}-50 p-4 rounded-xl mb-4`}>
                    <step.icon className={`w-8 h-8 text-${step.color}-500`} />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{step.title}</h3>
                  <div className="space-y-2">
                    {step.items.map((item, idx) => (
                      <motion.div
                        key={idx}
                        initial={{ opacity: 0, x: -10 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.1 * idx }}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <CheckCircle2 className={`w-4 h-4 mr-2 text-${step.color}-500`} />
                        {item}
                      </motion.div>
                    ))}
                  </div>
                </div>
                {index < 2 && (
                  <div className="h-8 w-1 bg-gradient-to-b from-transparent via-gray-300 to-transparent mx-auto my-2" />
                )}
              </motion.div>
            ))}
          </div>

          {/* Desktop Workflow View */}
          <div className="hidden lg:block perspective-[2000px]">
            <div className="flex items-center justify-between gap-8 transform-style-3d">
              {/* LIMS Card */}
              <motion.div
                initial={{ opacity: 0, rotateY: -20 }}
                whileInView={{ opacity: 1, rotateY: 0 }}
                whileHover={{ 
                  scale: 1.05,
                  rotateY: -5,
                  transition: { duration: 0.3 }
                }}
                viewport={{ once: true }}
                className="relative w-full lg:w-1/4 z-10"
              >
                <div className="bg-white/80 backdrop-blur-xl rounded-2xl p-6 shadow-2xl hover:shadow-red-500/10 transition-all duration-500 border border-white/20">
                  <div className="relative mb-4">
                    <div className="absolute -inset-1">
                      <div className="w-full h-full bg-gradient-to-r from-blue-500 to-blue-600 opacity-75 blur-lg" />
                    </div>
                    <div className="relative bg-gradient-to-br from-blue-50 to-blue-100 p-4 rounded-xl">
                      <Database className="w-8 h-8 text-blue-500" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{t('workflow.steps.lims.title')}</h3>
                  <div className="space-y-2">
                    <motion.div
                      initial={{ opacity: 0, x: -10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <FileText className="w-4 h-4 mr-2 text-blue-500" />
                      {t('workflow.steps.lims.items.raw')}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: -10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 }}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <Activity className="w-4 h-4 mr-2 text-blue-500" />
                      {t('workflow.steps.lims.items.unstructured')}
                    </motion.div>
                  </div>
                </div>
                {/* Animated Data Flow */}
                <motion.div 
                  animate={{
                    x: [0, 100],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "linear"
                  }}
                  className="hidden lg:block absolute top-1/2 -right-8 transform -translate-y-1/2"
                >
                  <div className="flex space-x-1">
                    {[1,2,3].map((_, i) => (
                      <div 
                        key={i}
                        className="w-2 h-2 rounded-full bg-blue-500"
                      />
                    ))}
                  </div>
                </motion.div>
              </motion.div>

              {/* Flow Line and Particles LIMS to AI */}
              <div className="relative flex-1 h-16">
                <FlowingParticles direction="right" />
                <motion.div
                  initial={{ scaleX: 0 }}
                  whileInView={{ scaleX: 1 }}
                  viewport={{ once: true }}
                  className="absolute top-1/2 -translate-y-1/2 w-full h-1 bg-gradient-to-r from-red-500/30 to-red-500/30 z-0"
                  style={{ transformOrigin: "left" }}
                />
              </div>

              {/* Enhanced AI Processing Card */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                whileHover={{ 
                  scale: 1.02,
                  rotateX: 5,
                  transition: { duration: 0.3 }
                }}
                viewport={{ once: true }}
                className="relative w-full lg:w-2/5 z-20"
              >
                <div className="bg-white/90 backdrop-blur-xl rounded-2xl p-8 shadow-2xl hover:shadow-red-500/20 transition-all duration-500 border border-white/20">
                  <div className="absolute inset-0 bg-gradient-to-br from-red-500/10 via-transparent to-blue-500/10 rounded-2xl" />
                  
                  {/* Neural Network Animation */}
                  <div className="absolute inset-0 overflow-hidden rounded-2xl opacity-20">
                    {[...Array(20)].map((_, i) => (
                      <motion.div
                        key={i}
                        className="absolute bg-gradient-to-r from-red-500 to-blue-500"
                        style={{
                          width: Math.random() * 100 + 50,
                          height: 1,
                          left: Math.random() * 100 + '%',
                          top: Math.random() * 100 + '%',
                        }}
                        animate={{
                          opacity: [0, 1, 0],
                          scale: [1, 2, 1],
                        }}
                        transition={{
                          duration: Math.random() * 3 + 2,
                          repeat: Infinity,
                          ease: "linear",
                        }}
                      />
                    ))}
                  </div>
                  
                  <div className="relative mb-6">
                    <motion.div
                      animate={{
                        scale: [1, 1.1, 1],
                        opacity: [0.7, 1, 0.7],
                      }}
                      transition={{
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                      className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-blue-500/20 rounded-full blur-xl"
                    />
                    <div className="relative bg-gradient-to-br from-red-50 to-red-100 p-6 rounded-xl">
                      <Brain className="w-12 h-12 text-red-500" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{t('workflow.steps.ai.title')}</h3>
                  
                  {/* Processing Animation */}
                  <div className="space-y-3">
                    <motion.div
                      animate={{
                        opacity: [0.5, 1, 0.5],
                        scale: [0.98, 1, 0.98],
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                      className="h-2 bg-gradient-to-r from-blue-500 to-red-500 rounded-full"
                    />
                    <motion.div
                      animate={{
                        opacity: [0.5, 1, 0.5],
                        scale: [0.98, 1, 0.98],
                      }}
                      transition={{
                        duration: 2,
                        delay: 0.3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                      className="h-2 bg-gradient-to-r from-red-500 to-green-500 rounded-full"
                    />
                  </div>

                  {/* Processing Steps */}
                  <div className="mt-6 space-y-2">
                    {[
                      t('workflow.steps.ai.items.normalization'),
                      t('workflow.steps.ai.items.pattern'),
                      t('workflow.steps.ai.items.risk'),
                      t('workflow.steps.ai.items.report')
                    ].map((step, index) => (
                      <motion.div
                        key={step}
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                        className="flex items-center text-sm text-gray-600"
                      >
                        <CheckCircle2 className="w-4 h-4 mr-2 text-red-500" />
                        {step}
                      </motion.div>
                    ))}
                  </div>
                </div>
                {/* Add Animated Data Flow */}
                <motion.div 
                  animate={{
                    x: [0, 100],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "linear"
                  }}
                  className="hidden lg:block absolute top-1/2 -right-6 transform -translate-y-1/2 z-[-1]"
                >
                  <div className="flex space-x-1">
                    {[1,2,3].map((_, i) => (
                      <div 
                        key={i}
                        className="w-2 h-2 rounded-full bg-green-500"
                      />
                    ))}
                  </div>
                </motion.div>
              </motion.div>

              {/* Flow Line and Particles AI to EHR */}
              <div className="relative flex-1 h-16">
                <FlowingParticles direction="right" />
                <motion.div
                  initial={{ scaleX: 0 }}
                  whileInView={{ scaleX: 1 }}
                  viewport={{ once: true }}
                  className="absolute top-1/2 -translate-y-1/2 w-full h-1 bg-gradient-to-r from-red-500 to-red-500 z-0"
                  style={{ transformOrigin: "left" }}
                />
              </div>

              {/* Enhanced EHR Card */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="relative w-full lg:w-1/4 z-20"
              >
                <div className="bg-white rounded-2xl p-6 shadow-xl hover:shadow-2xl transition-all duration-500">
                  <div className="relative mb-4">
                    <div className="absolute -inset-1">
                      <div className="w-full h-full bg-gradient-to-r from-green-500 to-green-600 opacity-75 blur-lg" />
                    </div>
                    <div className="relative bg-gradient-to-br from-green-50 to-green-100 p-4 rounded-xl">
                      <Database className="w-8 h-8 text-green-500" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{t('workflow.steps.ehr.title')}</h3>
                  <div className="space-y-2">
                    <motion.div
                      initial={{ opacity: 0, x: 10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <CheckCircle2 className="w-4 h-4 mr-2 text-green-500" />
                      {t('workflow.steps.ehr.items.structured')}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, x: 10 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.3 }}
                      className="flex items-center text-sm text-gray-600"
                    >
                      <Activity className="w-4 h-4 mr-2 text-green-500" />
                      {t('workflow.steps.ehr.items.automated')}
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        {/* Data Transformation Indicators */}
        <div className="mt-16 grid grid-cols-3 gap-8 max-w-3xl mx-auto">
          {[
            { label: t('workflow.indicators.raw'), icon: FileText, color: "blue" },
            { label: t('workflow.indicators.processing'), icon: Brain, color: "red" },
            { label: t('workflow.indicators.structured'), icon: CheckCircle2, color: "green" }
          ].map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="text-center"
            >
              <div className={`mx-auto w-12 h-12 rounded-xl bg-${step.color}-50 flex items-center justify-center mb-3`}>
                <step.icon className={`w-6 h-6 text-${step.color}-500`} />
              </div>
              <p className="text-sm font-medium text-gray-600">{step.label}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkflowVisualization;
