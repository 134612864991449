import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Menu, X, Globe, ArrowRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import BloodFlowLogo from "../../Assets/BloodflowLogoNoLetters.png"

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Navigation items
  const navItems = [
    { label: t('about'), href: '#features' },
    { label: t('features'), href: '#features' },
    { label: t('pricing'), href: '#pricing' },
    { label: t('contact'), href: '#contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <motion.nav
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
          isScrolled ? 'bg-white/70 backdrop-blur-xl shadow-lg' : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            {/* Logo with hover effect */}
            <RouterLink to="/" className="flex-shrink-0 group" onClick={handleLogoClick}>
              <motion.img 
                src={BloodFlowLogo} 
                alt="BloodFlow" 
                className="h-24 w-auto transform transition-transform duration-200 group-hover:scale-110"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.7 }}
              />
            </RouterLink>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-8">
              <div className="flex items-center gap-6">
                {navItems.map((item) => (
                  <motion.a
                    key={item.href}
                    href={item.href}
                    className="relative text-gray-600 px-3 py-2 text-sm font-medium transition-colors duration-200 hover:text-red-600"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {item.label}
                    <span className="absolute inset-x-0 bottom-0 h-0.5 bg-red-600 transform origin-left scale-x-0 transition-transform duration-200 group-hover:scale-x-100" />
                  </motion.a>
                ))}
              </div>

              {/* Language Selector - Modernized */}
              <div className="relative group">
                <motion.button 
                  className="flex items-center gap-2 px-3 py-2 rounded-lg text-gray-600 hover:text-red-600 transition-colors duration-200"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Globe className="h-4 w-4" />
                  <span className="text-sm font-medium">{i18n.language.toUpperCase()}</span>
                </motion.button>
                <div className="absolute right-0 mt-2 w-32 overflow-hidden">
                  <div className="relative bg-white rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 transform opacity-0 scale-95 invisible group-hover:opacity-100 group-hover:scale-100 group-hover:visible transition-all duration-200">
                    {['en', 'pt'].map((lang) => (
                      <button
                        key={lang}
                        onClick={() => i18n.changeLanguage(lang)}
                        className="block w-full text-left px-4 py-3 text-sm text-gray-700 hover:bg-red-50 hover:text-red-600 transition-colors duration-200"
                      >
                        {lang === 'en' ? 'English' : 'Português'}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Enhanced CTA Button */}
              <RouterLink to="/login">
                <motion.button 
                  className="group relative inline-flex items-center gap-2 px-5 py-2.5 rounded-xl bg-red-600 text-white font-medium text-sm shadow-lg hover:shadow-xl transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-red-600 to-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <span className="relative z-10">{t('navButton')}</span>
                  <ArrowRight className="relative z-10 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
                </motion.button>
              </RouterLink>
            </div>

            {/* Mobile Menu Button - Enhanced */}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="md:hidden inline-flex items-center justify-center p-2 rounded-xl text-gray-600 hover:text-red-600 hover:bg-red-50 transition-all duration-200"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Enhanced Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-40 md:hidden"
          >
            <motion.div 
              className="absolute inset-0 bg-black/20 backdrop-blur-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', damping: 30, stiffness: 300 }}
              className="fixed right-0 top-0 bottom-0 w-full max-w-sm bg-white shadow-2xl rounded-l-2xl"
            >
              <div className="flex flex-col h-full">
                <div className="flex-1 pt-24 pb-6 px-6">
                  <div className="flex flex-col space-y-1">
                    {navItems.map((item, index) => (
                      <motion.a
                        key={item.href}
                        href={item.href}
                        initial={{ x: 50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: index * 0.1 }}
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="group flex items-center py-4 text-lg font-medium text-gray-900 hover:text-red-600"
                      >
                        <span>{item.label}</span>
                        <ArrowRight className="ml-auto h-5 w-5 transform transition-transform duration-200 group-hover:translate-x-1" />
                      </motion.a>
                    ))}
                  </div>
                </div>
                <div className="p-6 bg-gray-50 rounded-l-2xl">
                  <RouterLink to="/login">
                    <motion.button 
                      className="w-full py-4 px-6 rounded-xl bg-red-600 text-white font-medium hover:bg-red-700 transition-colors duration-200"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {t('navButton')}
                    </motion.button>
                  </RouterLink>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
