import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, X } from 'lucide-react';

const DeleteAccountModal = ({ show, onClose, onConfirm, t }) => {
  if (!show) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-[60] flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        <div className="relative bg-red-50 p-6">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-white/10 transition-colors"
          >
            <X size={20} />
          </button>
          
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-16 h-16 bg-red-100 rounded-full">
              <AlertTriangle className="w-8 h-8 text-red-600" />
            </div>
            
            <h3 className="mt-4 text-xl font-semibold text-center text-gray-900">
              {t('deleteAccount')}
            </h3>
            
            <p className="mt-2 text-sm text-center text-gray-600 max-w-sm">
              {t('deleteAccountWarning')}
            </p>
          </div>
        </div>
        
        <div className="p-6 bg-white space-y-3">
          <button
            onClick={onConfirm}
            className="w-full px-4 py-3 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-xl transition-colors flex items-center justify-center gap-2"
          >
            {t('confirmDelete')}
          </button>
          
          <button
            onClick={onClose}
            className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-xl transition-colors"
          >
            {t('cancel')}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DeleteAccountModal;